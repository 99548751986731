export class AuthHashEntity {
    email!: string;
    firebaseId!: string;
    role?: string;
    idCompany?: string;
    id?: string;
    timestamp?: number;
    admin!: boolean;

    constructor({
                    email,
                    role,
                    idCompany,
                    firebaseId,
                    id,
                    timestamp,
                    admin,
                }: {
        email: string;
        firebaseId: string;
        role?: string;
        idCompany?: string;
        id?: string;
        timestamp?: number;
        admin: boolean;

    }) {
        Object.assign(this, {
            email,
            role,
            idCompany,
            firebaseId,
            id,
            timestamp,
            admin
        });
    }

    copyWith({
                 email,
                 role,
                 idCompany,
                 firebaseId,
                 id,
                 timestamp,
                 admin
             }: {
        email?: string;
        role?: string;
        idCompany?: string;
        firebaseId?: string;
        id?: string;
        timestamp?: number;
        admin?: boolean;

    }): AuthHashEntity {
        return new AuthHashEntity({
            firebaseId: firebaseId ?? this.firebaseId,
            email: email ?? this.email,
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            role: role ?? this.role,
            timestamp: timestamp ?? this.timestamp,
            admin: admin ?? this.admin
        })
    }
}
