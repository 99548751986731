import {inject} from "inversify";
import {action, configure, makeObservable, observable} from "mobx";
import {injectable} from "inversify";
import {LoginUseCase} from "@dropDesk/domain/use_case/auth/sign_in.usecase";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {RecoveryAccountUseCase} from "@dropDesk/domain/use_case/auth/send_password_reset_email";
import {translate} from "@dropDesk/storage/i18n/translate_helper";
import {LoginServerUseCase} from "@dropDesk/domain/use_case/auth/login_server.usecase";
import {LogoutByAdminUseCase} from "@dropDesk/domain/use_case/auth/logout_by_admin.usecase";
import {LogoutUseCase} from "@dropDesk/domain/use_case/auth/logout.usecase";
import firebaseAuth from "firebase/auth";
import {GetUserByFirebaseUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_firebase.usecase";
import {GetUserByDecodedTokenUseCase} from "@dropDesk/domain/use_case/auth/get_user_by_decoded_token_firebase.usecase";
import {MailAction} from "@dropDesk/domain/entities/common/actions_entity";
import {StartMigrationUseCase} from "@dropDesk/domain/use_case/auth/start_migration.usecase";
import {MigrateUseCase} from "@dropDesk/domain/use_case/auth/migrate.usecase";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";
import {Resend2FAUseCase} from "@dropDesk/domain/use_case/auth/resend2FA.usecase";

configure({
    enforceActions: "always",
});

@injectable()
export class LoginController {
    private readonly _signInuseCase: LoginUseCase;
    private readonly _loginServerUseCase: LoginServerUseCase;
    private readonly _recoveryAccount: RecoveryAccountUseCase;
    private readonly _logoutByAdminUseCase: LogoutByAdminUseCase;
    private readonly _logoutUseCase: LogoutUseCase;
    private readonly _getUserByFirebaseUseCase: GetUserByFirebaseUseCase;
    private readonly _getUserByDecodedTokenUseCase: GetUserByDecodedTokenUseCase;
    private readonly _startMigrationUseCase: StartMigrationUseCase;
    private readonly _migrateUseCase: MigrateUseCase;
    private readonly _resend2FAUseCase: Resend2FAUseCase;

    constructor(
        @inject(LoginUseCase) signInuseCase: LoginUseCase,
        @inject(RecoveryAccountUseCase) recoveryAccount: RecoveryAccountUseCase,
        @inject(LoginServerUseCase) loginServerUseCase: LoginServerUseCase,
        @inject(LogoutByAdminUseCase) logoutByAdminUseCase: LogoutByAdminUseCase,
        @inject(LogoutUseCase) logoutUseCase: LogoutUseCase,
        @inject(GetUserByFirebaseUseCase) getUserByFirebaseUseCase: GetUserByFirebaseUseCase,
        @inject(GetUserByDecodedTokenUseCase) getUserByDecodedTokenUseCase: GetUserByDecodedTokenUseCase,
        @inject(StartMigrationUseCase) startMigrationUseCase: StartMigrationUseCase,
        @inject(MigrateUseCase) migrateUseCase: MigrateUseCase,
        @inject(Resend2FAUseCase) resend2FAUseCase: Resend2FAUseCase,
    ) {
        makeObservable(this);
        this._signInuseCase = signInuseCase;
        this._recoveryAccount = recoveryAccount;
        this._loginServerUseCase = loginServerUseCase;
        this._logoutUseCase = logoutUseCase;
        this._logoutByAdminUseCase = logoutByAdminUseCase;
        this._getUserByFirebaseUseCase = getUserByFirebaseUseCase;
        this._getUserByDecodedTokenUseCase = getUserByDecodedTokenUseCase;
        this._startMigrationUseCase = startMigrationUseCase;
        this._migrateUseCase = migrateUseCase;
        this._resend2FAUseCase = resend2FAUseCase;
    }

    @observable
    loading = false;

    @observable
    loadingMessage?: string | null = null;

    @observable
    userFirebase: firebaseAuth.User | null = null;

    @action
    setUserFirebase(user: firebaseAuth.User | null) {
        this.userFirebase = user;
    }

    @action
    recoveryAccount = async (email: string, onSuccess: (key: string) => void): Promise<void> => {
        try {
            this.startLoading();
            await this._recoveryAccount.call(email);
            onSuccess("auth.success.recovery_account");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    login = async (loginEntity: {
        email: string;
        password: string;
    }, onSuccess: Function, onSuccessLegacy?: Function,): Promise<void> => {
        try {
            this.startLoading("signIn.loading");
            const response = await this._signInuseCase.call(loginEntity.email, loginEntity.password);
            if (response.success) {
                onSuccess();
            } else if (onSuccessLegacy) {
                onSuccessLegacy();
            }
            this.stopLoading();
        } catch (err: any) {
            if (err.message === DropDeskErrorEnum.requiredTwoFactorAuth) {
                toastMessage.info(translate(`server_messages.${err.message ?? 'unknown'}`));
            } else {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            }
            this.stopLoading();
        }
    };

    @action
    startMigration = async (email: string, onSuccess: (key: string, email?: string) => void, action?: MailAction): Promise<void> => {
        try {
            this.startLoading("signIn.startMigration");
            await this._startMigrationUseCase.call(email, action);
            onSuccess("signIn.sendEmailMigration");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    resend2FA = async (
        email: string, onSuccess: (key: string) => void
    ): Promise<void> => {
        try {
            this.startLoading("signIn.resend2FA");
            await this._resend2FAUseCase.call(email);
            onSuccess("signIn.success.resend2FA");
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    migrate = async (email: string, password: string, token: string, onSuccess: (key: string, user: UserEntity) => void): Promise<void> => {
        try {
            this.startLoading("signIn.changePassword");
            const user = await this._migrateUseCase.call(email, password, token);
            onSuccess("signIn.success.changePassword", user);
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    };

    @action
    loginServer = async (token?: string): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                this.startLoading("signIn.loading");
                await this._loginServerUseCase.call(token);
                this.stopLoading();
                return resolve();
            } catch (err: any) {
                toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
                this.stopLoading();
                return reject(err);
            }
        })
    };

    @action
    logout = async (onSuccess: () => void): Promise<void> => {
        try {
            this.startLoading("signIn.logout");
            await this._logoutUseCase.call();
            onSuccess();
            this.stopLoading();
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    logoutByAdmin = async (onSuccess: (key: string) => void, idUser: string): Promise<void> => {
        try {
            this.startLoading("signIn.loadingLogoutByAdmin");
            await this._logoutByAdminUseCase.call(idUser);
            this.stopLoading();
            onSuccess("signIn.success.logoutByAdmin");
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getUserByFirebase = async (): Promise<firebaseAuth.User | null | undefined> => {
        try {
            const user = await this._getUserByFirebaseUseCase.call();
            this.setUserFirebase(user);
            return user;
        } catch (err: any) {
            toastMessage.error(translate(`server_messages.${err.message ?? 'unknown'}`));
            this.stopLoading();
        }
    }

    @action
    getUserByDecodedToken = (): Record<string, unknown> | null => {
        return this._getUserByDecodedTokenUseCase.call();
    }

    @action
    async initializeConfirmEmail(onSuccessGetUser: (user: firebaseAuth.User | null) => void): Promise<void> {
        await this.getUserByFirebase();
        onSuccessGetUser(this.userFirebase);
    }

    @action
    setLoadingMessage(message?: string | null) {
        this.loadingMessage = message;
    }

    @action
    startLoading(loadingMessage?: string | null) {
        this.setLoadingMessage(loadingMessage);
        this.loading = true;
    }

    @action
    stopLoading() {
        this.loading = false;
        this.setLoadingMessage(null);
    }
}
