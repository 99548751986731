export enum DropDeskErrorEnum {
    creditCardExpired = 'credit_card_expired',
    creditCardInvalidNumber = 'credit_card_invalid_number',
    creditCardInvalidCVV = 'credit_card_invalid_cvv',
    creditCardInvalidHolderNome = 'credit_card_holder_name',
    creditCardInvalid = 'credit_card_invalid',
    adBlockError = 'ad_block_error',
    operationCanceledDueToNewRequest = 'operation_canceled_due_to_new_request',
    companySubscriptionNotFound = 'company_subscription_not_found',
    passwordReuseNotAllowed = 'password_reuse_not_allowed',
    requiredTwoFactorAuth = 'required_two_factor_auth',
}
