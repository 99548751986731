import {observer} from "mobx-react";
import {ContainerHeader, SubText, TextHeader} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";


const HeaderForm = observer((
    {
        textHeader,
        isConfirmation,
        hasDefaultToken,
        isMigrated
    }: {
        textHeader: string,
        isConfirmation: boolean
        hasDefaultToken: boolean
        isMigrated: boolean
    }) => {
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    return (
        <ContainerHeader>
            <TextHeader color={colors.text}>{textHeader}</TextHeader>
            <SubText
                color={colors.text}>{isConfirmation ?
                `Obrigado por utilizar o DropDesk, clique em ativar conta para terminar o processo de segurança da sua conta.` :
                `${!hasDefaultToken ? 'Enviamos um código para seu email, insira o código e c' : 'C'}rie uma senha forte para garantir sua segurança.`}

            </SubText>

            {isMigrated && <SubText color={colors.text} style={{marginTop: 8, fontWeight: "bold"}}>
                Caso não tenha acesso ao email, entre em contato com o suporte DropDesk.
            </SubText>}
        </ContainerHeader>
    );
});

export default HeaderForm;
