import "reflect-metadata";
import {inject, injectable} from "inversify";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";
import {MailAction} from "@dropDesk/domain/entities/common/actions_entity";


@injectable()
export class Resend2FAUseCase {
    private _repository: AuthRepository;

    constructor(
        @inject(AuthRepository) repository: AuthRepository
    ) {
        this._repository = repository;
    }

    public call(email: string): Promise<void> {
        return this._repository.resend2FA(email);
    }
}
