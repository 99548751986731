import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {
    CompanyCreateAccount,
    CompanyEntity,
    CompanyExtraInfoEntity
} from "@dropDesk/domain/entities/company/company.entity";
import {AuthRemoteDataSource} from "@dropDesk/data/data_source/auth/auth_remote.datasource";
import firebaseAuth from "firebase/auth";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {CompanyConnectionsEntity} from "@dropDesk/domain/entities/company/company_connections.entity";
import {SimpleUserInfo, UserEntity, UserInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import {SectorEntity} from "@dropDesk/domain/entities/sector/sector.entity";
import {getTokenDecoded, saveToken} from "@dropDesk/data/data_source/token/token.datasource";
import {PaymentMethodEntity} from "@dropDesk/domain/entities/payment_method/payment_method.entity";
import {ThemeConfigurationEntity} from "@dropDesk/domain/entities/company_configurations/theme_configuration.entity";
import {ClientCreateAccountEntity} from "@dropDesk/domain/entities/client/client_create_account_entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {CompanyLogsEntity, CompanyLogType} from "@dropDesk/domain/entities/company/company_logs.entity";
import {navigate} from "@dropDesk/utils/helpers/navigation";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {DropDeskError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";
import {AuthHashClient} from "@dropDesk/data/clients/auth_hash_client";

@injectable()
export abstract class CompanyRemoteDataSource {

    public abstract set(company: CompanyEntity): Promise<CompanyEntity>;

    public abstract findByPK(): Promise<CompanyEntity>;

    public abstract createAccount(
        company?: CompanyEntity,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<CompanyCreateAccount>;

    public abstract listActiveConnections(page: number, limit: number, searchParam: string):
        Promise<ListPaginationEntity<CompanyConnectionsEntity>>;

    public abstract listCompanySubscriptionLogs(page: number, limit: number): Promise<ListPaginationEntity<CompanyLogsEntity>>;

    public abstract setCompanyExtraInfo(extraInfo: CompanyExtraInfoEntity): Promise<CompanyEntity>;

    public abstract setPaymentMethod(input: PaymentMethodEntity): Promise<PaymentMethodEntity>;

    public abstract getThemeByUniqueCode(uniqueCode: number): Promise<ThemeConfigurationEntity | null>;

    public abstract updateLegacy(body: Record<string, any>): Promise<void>;

}

@injectable()
export class CompanyRemoteDataSourceImpl implements CompanyRemoteDataSource {

    private _authDataSource: AuthRemoteDataSource;

    constructor(
        @inject(AuthRemoteDataSource) authDataSource: AuthRemoteDataSource,
    ) {
        this._authDataSource = authDataSource;

    }

    baseCompanyUrl: string = 'company/';
    baseCreateCompanyUrl: string = `${this.baseCompanyUrl}createAccount`;
    baseSetCompanyUrl: string = `${this.baseCompanyUrl}set`;
    listConnectionUrl: string = `${this.baseCompanyUrl}activeConnections`;
    lisLogsUrl: string = `${this.baseCompanyUrl}logs`;
    setExtraInfo: string = `${this.baseCompanyUrl}setExtraInfo`;
    baseGetThemeByUniqueCode: string = `${this.baseCompanyUrl}getThemeByUniqueCode`;
    baseSetPaymentMethod: string = `${this.baseCompanyUrl}paymentMethod/set`;
    baseCreateClientAccountUrl: string = `${this.baseCompanyUrl}createClientAccount`;
    updateLegacyUrl: string = `${this.baseCompanyUrl}updateLegacy`;

    public createAccount(
        company?: CompanyEntity,
        sector?: SectorEntity,
        user?: UserEntity,
        clientCreateAccount?: ClientCreateAccountEntity
    ): Promise<CompanyCreateAccount> {
        return new Promise<CompanyCreateAccount>(async (resolve, reject) => {
            let credentialUserInCreation: firebaseAuth.UserCredential | null = null;
            const email = user ? user.email : clientCreateAccount!.contact.email;
            const password = user ? user.password : clientCreateAccount!.contact.password;
            try {
                credentialUserInCreation = await this._authDataSource.createUser(email ?? '', password ?? '');
                const token = await credentialUserInCreation.user?.getIdToken(true);
                saveToken(token!);
                AuthHashClient.persistAuthHash(
                    user ?
                        UserInfoEntity.fromJson({
                            id: user.id,
                            idCompany: user.idCompany,
                            email: user.email,
                            role: UserRole.attendant,
                            admin: user.permissionAdmin
                        })
                        :
                        new UserInfoEntity({
                            id: clientCreateAccount!.contact.id!,
                            idCompany: clientCreateAccount!.contact.idCompany,
                            email: clientCreateAccount!.contact.email!,
                            role: UserRole.userClient,
                            admin: false
                        })
                );
                const data = {
                    company: company?.toCreateAccount(),
                    user: user?.toCreateAccount(),
                    sector: sector?.toCreateAccount(),
                    client: clientCreateAccount?.toJson()
                };
                const url = clientCreateAccount ? this.baseCreateClientAccountUrl : this.baseCreateCompanyUrl;
                const response = await api.post(url, data);
                const newTokenWithClaims = await credentialUserInCreation.user?.getIdToken(true);
                saveToken(newTokenWithClaims!);
                const companyCreateAccount = response.data as CompanyCreateAccount;

                if (companyCreateAccount.needLogin) {
                    await this._authDataSource.loginServer();
                }

                return resolve(response.data);

            } catch (error: any) {

                localStorage.removeItem(process.env.STORAGE_TOKEN as any);
                localStorage.removeItem(process.env.STORAGE_COMPANY as any);

                if (credentialUserInCreation && credentialUserInCreation.user) {
                    await credentialUserInCreation.user.delete();
                }

                return reject(parseServerError(error));

            }
        });

    }

    public set(company: CompanyEntity): Promise<CompanyEntity> {
        return new Promise<CompanyEntity>(async (resolve, reject) => {
            try {
                const response = await api.post(this.baseSetCompanyUrl, company.toJson());
                return resolve(CompanyEntity.fromJson(response.data));
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public findByPK(): Promise<CompanyEntity> {
        return new Promise<CompanyEntity>(async (resolve, reject) => {
            try {
                const payload = getTokenDecoded();
                const response = await api.get(this.baseCompanyUrl + payload!.idCompany);
                const company = CompanyEntity.fromJson(response.data);
                if (!company.paymentSubscriptions || !company.paymentSubscriptions.length) {
                    await this._authDataSource.logout();
                    navigate(RoutesEnum.login);
                    throw new DropDeskError(DropDeskErrorEnum.companySubscriptionNotFound);
                }
                return resolve(company);
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public updateLegacy(body: Record<string, any>): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.put(`${this.updateLegacyUrl}`, body));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public setCompanyExtraInfo(extraInfo: CompanyExtraInfoEntity): Promise<CompanyEntity> {
        return new Promise<CompanyEntity>(async (resolve, reject) => {
            try {
                const response = await api.put(`${this.setExtraInfo}`, extraInfo);
                return resolve(CompanyEntity.fromJson(response.data));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public listActiveConnections(page: number, limit: number, searchParam: string):
        Promise<ListPaginationEntity<CompanyConnectionsEntity>> {
        return new Promise<ListPaginationEntity<CompanyConnectionsEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.listConnectionUrl}?page=${page ?? ''}&limit=${limit ?? ''}&search=${searchParam ?? ''}`);
                const result = new ListPaginationEntity<CompanyConnectionsEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: CompanyConnectionsEntity) => new CompanyConnectionsEntity({
                            ...entry,
                        }),
                    ),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public listCompanySubscriptionLogs(page: number, limit: number): Promise<ListPaginationEntity<CompanyLogsEntity>> {
        return new Promise<ListPaginationEntity<CompanyLogsEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.lisLogsUrl}?page=${page ?? ''}&limit=${limit ?? ''}`);
                const result = new ListPaginationEntity<CompanyLogsEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    data: response.data.data.map((entry: Record<string, any>) => CompanyLogsEntity.fromJson(entry)),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public setPaymentMethod(input: PaymentMethodEntity): Promise<PaymentMethodEntity> {
        return new Promise<PaymentMethodEntity>(async (resolve, reject) => {
            try {
                const response = await api.post(this.baseSetPaymentMethod, input);
                const result: PaymentMethodEntity = PaymentMethodEntity.fromJson(response.data);
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }

    public getThemeByUniqueCode(uniqueCode: number): Promise<ThemeConfigurationEntity | null> {
        return new Promise<ThemeConfigurationEntity | null>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseGetThemeByUniqueCode}?uniqueCode=${uniqueCode}`);
                const result: ThemeConfigurationEntity | null = response.data ? ThemeConfigurationEntity.fromJson(response.data) : null;
                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        });
    }
}
