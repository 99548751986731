import axios, {AxiosResponse, CancelTokenSource} from "axios";
import {DropDeskException, ServerError} from "@dropDesk/domain/entities/exceptions/exceptions";
import {AuthHashClient} from "@dropDesk/data/clients/auth_hash_client";
import {DropDeskErrorEnum} from "@dropDesk/domain/entities/exceptions/dropdesk_error_enum";

const api = axios.create({
    headers: {},
    baseURL: process.env.BASE_API as string,
    timeout: process.env.MODE === 'production' ? 1000 * 60 : 1000 * 600
});

const authHashClient = new AuthHashClient();

export const OPEN_ROUTES = [
    'company/getThemeByUniqueCode',
    'users/activateAccount',
    'users/forgot',
    'users/change-password',
    'users/start-migration',
    'contracts/toSignUp',
    'segments/',
    'tickets/getGlobalConfigs',
    'users/migrate'
];

api.interceptors.request.use(async (request) => {
    await authHashClient.setHeaders(request);
    if (process.env.MODE === 'development' || process.env.MODE === 'uat') {
        console.log('request url', request.url);
    }
    return request;
});

api.interceptors.response.use((response) => {
    return response
}, (error) => {
    return authHashClient.responseInterceptor(error, api);
});

export const parseServerError = (error: any): DropDeskException => {
    let err: ServerError = new ServerError(error.code ?? error.message ?? 'unknown');
    if (axios.isCancel(error)) {
        err = new ServerError(error.message);
    } else if (axios.isAxiosError(error) && error.response) {
        const response = error.response as AxiosResponse;
        err = new ServerError(response.data.error);
    }
    return err;
}



export class CancelTokenManager {
    private static currentCancelTokenSource: CancelTokenSource | null = null;

    static createNewToken(): CancelTokenSource {
        if (this.currentCancelTokenSource) {
            this.currentCancelTokenSource.cancel(DropDeskErrorEnum.operationCanceledDueToNewRequest);
        }
        const cancelTokenSource = axios.CancelToken.source();
        this.currentCancelTokenSource = cancelTokenSource;
        return cancelTokenSource;
    }

    static clearToken() {
        this.currentCancelTokenSource = null;
    }

    static cancelToken() {
        if (this.currentCancelTokenSource) {
            this.currentCancelTokenSource.cancel(DropDeskErrorEnum.operationCanceledDueToNewRequest);
        }
    }
}

export default api;
