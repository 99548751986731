import {
    ButtonPlay,
    Container,
    Content, ContentFakeControls,
    ContentImage, OverlayImage, Seconds
} from "@dropDesk/presentation/components/messages/message_box/video_message/styled";
import React from "react";
import LinkFy from "@dropDesk/presentation/components/linkify";
import {observer} from "mobx-react";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {Image} from "antd";
import {PlaceHolderImage} from "@dropDesk/presentation/components/messages/message_box/image_message/styled";
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import corruptFile from "@dropDesk/storage/images/corrupt-file.png";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {formatDuration} from "@dropDesk/utils/helpers/date_helper";

const VideoMessage = observer((
    {
        message,
        color,
        showOriginalFile,
        hint,
        setLoaded
    }: {
        message: TicketMessageEntity
        color: string;
        showOriginalFile: (message: TicketMessageEntity) => void
        setLoaded: (message: TicketMessageEntity) => void
        hint: string;
    }
) => {


    const handleShowOriginalFile = (): void => {
        showOriginalFile(message);
    }
    const showThumbNail = !message.showOriginal && !!message.videoMessage?.jpegThumbnail;

    return (
        <Container>
            <Content>
                {showThumbNail &&
                    <ContentImage>
                        <Image
                            preview={{
                                visible: false,
                                onVisibleChange: (visible, prevVisible) => {
                                    if (visible) {
                                        handleShowOriginalFile();
                                    }
                                },
                                mask: false,
                                style: {cursor: 'pointer'}
                            }}
                            placeholder={(
                                <PlaceHolderImage hint={hint}>
                                    <SpinLoading
                                        color={color}
                                        size={"default"}
                                    />
                                </PlaceHolderImage>
                            )}
                            width={170}
                            src={message.realUrl}
                            fallback={corruptFile}
                            style={{border: 0, maxHeight: 300, height: 300}}
                            alt={"Vídeo"}
                        />
                        <OverlayImage onClick={(event) => {
                            event.stopPropagation();
                            handleShowOriginalFile();
                        }}>
                            <ContentFakeControls>
                                <ButtonPlay>
                                    <DropDeskIcon icon={ICONS_DROPDESK.playButton} size={11}
                                                  color={SharedColors.white}/>
                                </ButtonPlay>
                                <Seconds>0:00&nbsp;/&nbsp;{formatDuration(message.videoMessage?.seconds ?? 0)}</Seconds>
                            </ContentFakeControls>
                        </OverlayImage>
                    </ContentImage>
                }
                {(message.showOriginal || !message.videoMessage?.jpegThumbnail) &&
                    <video
                        width={300}
                        height={300}
                        controls
                        autoPlay={!!message.videoMessage?.jpegThumbnail && !message.loaded}
                        onPlay={() => {
                            if (!message.loaded) {
                                setLoaded(message);
                            }
                        }}>
                        <source src={message.urlDownload} type='video/mp4'/>
                        Seu navegador não tem suporte para video em HTML.
                    </video>}
            </Content>
            {message.videoMessage?.caption && <LinkFy text={message.videoMessage!.caption} color={color}/>}
        </Container>
    )
});
export default VideoMessage;
