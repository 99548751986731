import "reflect-metadata";
import {inject, injectable} from "inversify";
import api, {parseServerError} from "@dropDesk/data/clients/http.client";
import {UserEntity, UserExtraInfoEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {RemoteStorageDatasource} from "@dropDesk/data/data_source/remote_storage/remote_storage_datasource";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {AuthRemoteDataSource} from "@dropDesk/data/data_source/auth/auth_remote.datasource";
import {SectorUsersEntity} from "@dropDesk/domain/entities/sectors_users/sectors_users.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {IoRemoteDataSource} from "@dropDesk/data/data_source/io/io_remote.datasource";
import {ChangePasswordResponse} from "@dropDesk/domain/entities/common/account_action.entity";
import {ExportRemoteDataSource} from "@dropDesk/data/data_source/export/export_remote.datasource";
import {Observable, Observer} from "rxjs";
import {ExportDataEntity} from "@dropDesk/domain/entities/export_data/export_data_entity";
import {ChatRemoteDataSource} from "@dropDesk/data/data_source/chat/chat_remote_datasource";

@injectable()
export abstract class UserRemoteDataSource {
    public abstract set(user: UserEntity, isNew: boolean): Promise<UserEntity>;

    public abstract list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        role: UserRole,
        idClient?: string,
        idSector?: string,
        listOnlyContactWithWhatsApp?: boolean,
    ): Promise<ListPaginationEntity<UserEntity>>;

    public abstract restore(users: UserEntity[], role: UserRole): Promise<void>;

    public abstract delete(users: UserEntity[]): Promise<void>;

    public abstract findByPK(id: string, role?: UserRole): Promise<UserEntity>;

    public abstract changePassword(renewToken: string, password?: string): Promise<ChangePasswordResponse>;

    public abstract forgot(email: string, retry?: boolean): Promise<void>;

    public abstract activateAccount(email: string, retry?: boolean): Promise<void>;

    public abstract transferUser(idNewClient: string, idUser: string): Promise<void>;

    public abstract changeEmail(oldEmail: string, newEmail: string, isCurrentUserChanged: boolean): Promise<void>;

    public abstract setTheme(theme: string): Promise<void>;

    public abstract export(role: UserRole, id?: string): Promise<ExportDataEntity>;

    public abstract exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null>;
}

@injectable()
export class UserRemoteDataSourceImpl implements UserRemoteDataSource {
    private _remoteDataSource: RemoteStorageDatasource;
    private _authDataSource: AuthRemoteDataSource;
    private _ioRemoteDataSource: IoRemoteDataSource;
    private _exportDataSource: ExportRemoteDataSource;
    private _chatRemoteDataSource: ChatRemoteDataSource;

    constructor(
        @inject(RemoteStorageDatasource) dataSource: RemoteStorageDatasource,
        @inject(AuthRemoteDataSource) authDataSource: AuthRemoteDataSource,
        @inject(IoRemoteDataSource) ioRemoteDataSource: IoRemoteDataSource,
        @inject(ExportRemoteDataSource) exportDataSource: ExportRemoteDataSource,
        @inject(ChatRemoteDataSource) chatRemoteDataSource: ChatRemoteDataSource
    ) {
        this._ioRemoteDataSource = ioRemoteDataSource;
        this._remoteDataSource = dataSource;
        this._authDataSource = authDataSource;
        this._exportDataSource = exportDataSource;
        this._chatRemoteDataSource = chatRemoteDataSource;
    }

    baseUserUrl: string = `users/`;
    baseDeleteUserUrl: string = `${this.baseUserUrl}${UserRole.attendant}/`;
    baseUrlTransferUser: string = `${this.baseUserUrl}${UserRole.userClient}/transfer-user`;
    baseUrlForgot: string = `${this.baseUserUrl}forgot/`;
    baseUrlActivateAccount: string = `${this.baseUserUrl}activateAccount/`;
    baseUrlChangePassword: string = `${this.baseUserUrl}change-password/`;
    baseUrlChangeEmail: string = `${this.baseUserUrl}change-email/`;
    baseUrlSetTheme: string = `${this.baseUserUrl}setTheme/`;
    baseUrlSetNotification: string = `${this.baseUserUrl}setNotification`;


    public async list(
        page: number,
        searchParam: string,
        limit: number,
        listOnlyDeleted: boolean,
        role: UserRole,
        idClient?: string,
        idSector?: string,
        listOnlyContactWithWhatsApp?: boolean,
    ): Promise<ListPaginationEntity<UserEntity>> {
        return new Promise<ListPaginationEntity<UserEntity>>(async (resolve, reject) => {
            try {
                const response = await api.get(`${this.baseUserUrl}?role=${role ?? ''}&searchParam=${searchParam ?? ''}&page=${page ?? ''}&limit=${limit ?? ''}&type=${listOnlyDeleted ? 'deleted' : ''}&idClient=${idClient ?? ''}&idSector=${idSector ?? ''}&listOnlyContactWithWhatsApp=${listOnlyContactWithWhatsApp ?? false}`);
                const result = new ListPaginationEntity<UserEntity>({
                    page,
                    limit,
                    totalRows: response.data.totalRows,
                    pages: response.data.pages,
                    hasMore: response.data.page < (response.data.pages - 1),
                    data: response.data.data.map((entry: UserEntity) => UserEntity.fromJson(entry),
                    ),
                });

                return resolve(result);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public set(user: UserEntity, isNew: boolean): Promise<UserEntity> {
        return new Promise<UserEntity>(async (resolve, reject) => {

            const isUploadProfileImage: boolean = !!user.urlImageProfile && !user.urlImageProfile.startsWith('http');
            const pathReference = isUploadProfileImage ? user.imagePathReference() : '';

            try {
                const sectors: SectorUsersEntity[] = user.sectors.filter(sector => sector.action !== BackendAction.nil) ?? [];
                const _user = user.copyWith({
                    sectors: sectors.map((entry) => entry.removeCalculatedVariables())
                });

                await this.deleteImages(user);
                if (isUploadProfileImage) {
                    const file = await this._makeFileToUpload(user.urlImageProfile!);
                    _user.urlImageProfile = await this._remoteDataSource.uploadFile(file, pathReference);
                }

                const response = await api.post(`${this.baseUserUrl}${user.role}/set/`, _user.toJson());
                this._chatRemoteDataSource.updateUsersCache([_user]).then();
                return resolve(UserEntity.fromJson(response.data));

            } catch (error: any) {

                if (isNew && isUploadProfileImage && pathReference) {
                    await this._remoteDataSource.removeFile(pathReference);
                }

                return reject(parseServerError(error));
            }
        })
    }

    public activateAccount(email: string, retry?: boolean): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.patch(this.baseUrlActivateAccount + email + (retry ? `/retry` : '')));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public setTheme(theme: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.put(this.baseUrlSetTheme, {name: theme}));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public transferUser(idNewClient: string, idUser: string): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.patch(this.baseUrlTransferUser, {idNewClient, idUser}));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public forgot(email: string, retry?: boolean): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                return resolve(await api.patch(this.baseUrlForgot + email + (retry ? `/retry` : '')));
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public changePassword(renewToken: string, password?: string): Promise<ChangePasswordResponse> {
        return new Promise<ChangePasswordResponse>(async (resolve, reject) => {
            try {
                const response = await api.patch(this.baseUrlChangePassword, {renewToken, password});
                const accountAction = new ChangePasswordResponse({
                    ...response.data
                });
                return resolve(accountAction);
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }



    public changeEmail(oldEmail: string, newEmail: string, isCurrentUserChanged: boolean): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                await api.patch(this.baseUrlChangeEmail, {newEmail, oldEmail});
                if (isCurrentUserChanged) {
                    await this._authDataSource.logout();
                }
                return resolve();
            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public restore(users: UserEntity[], role: UserRole): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const url = `${this.baseUserUrl}${role}/restore/`;

                for (let i = 0; i < users.length; i++) {
                    await api.patch(url + users[i].id);
                }
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

    public delete(users: UserEntity[]): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                for (let i = 0; i < users.length; i++) {
                    await api.delete(this.baseDeleteUserUrl + users[i].id);
                }
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }


    public findByPK(id: string, role?: UserRole): Promise<UserEntity> {
        return new Promise<UserEntity>(async (resolve, reject) => {
            try {
                const url = `${this.baseUserUrl}${role ?? UserRole.attendant}/${id}`;
                const response = await api.get(url);
                return resolve(UserEntity.fromJson(response.data));
            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public export(role: UserRole, id?: string): Promise<ExportDataEntity> {
        return new Promise<ExportDataEntity>(async (resolve, reject) => {
            try {
                const url = `${this.baseUserUrl}export/${role}/start?id=${id ?? ''}`;
                const response = await api.get(url);
                const exportData = new ExportDataEntity({
                    ...response.data,
                    progress: parseFloat((response.data.progress * 100).toFixed(2)),
                    responseStatus: response.status
                });
                return resolve(exportData);

            } catch (error: any) {
                return reject(parseServerError(error));
            }
        })
    }

    public exportSubscription(
        id: string,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void,
        useSocket: boolean
    ): Observable<ExportDataEntity | null> {
        return new Observable<ExportDataEntity | null>((observer: Observer<ExportDataEntity | null>) => {
            return this._exportDataSource.exportSubscription(
                'users',
                id,
                handleDisconnect,
                getLastUpdates,
                useSocket
            ).subscribe({
                next: (response) => {
                    observer.next(response);
                },
                error(err: any) {
                    observer.error(err);
                }
            })
        });
    }

    private async _makeFileToUpload(urlFile: string): Promise<File> {
        const blob = await this._ioRemoteDataSource.urlToBlob(urlFile);
        return new File([blob], `profile_image.png`, {type: 'image/png'});
    }

    private async deleteImages(user: UserEntity): Promise<void> {
        return new Promise<void>(async (resolve, reject) => {
            try {
                let pathToExclude: string | undefined;

                if (!user.urlImageProfile) {

                    const _url = await this._remoteDataSource.getDownloadUrl(user.imagePathReference())
                    if (!!_url) {
                        pathToExclude = user.imagePathReference();
                    }

                }

                if (pathToExclude) {
                    await this._remoteDataSource.removeFile(pathToExclude);
                }
                return resolve();

            } catch (error) {
                return reject(parseServerError(error));
            }
        })
    }

}
