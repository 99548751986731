import {ListPaginationChatEntity} from '@dropDesk/domain/entities/chat/list_pagination_chat.entity';

export class ListPaginationAllChatsEntity {
  userChats!: ListPaginationChatEntity;
  waitingChats!: ListPaginationChatEntity;

  constructor({
    userChats,
    waitingChats
  }: {
    userChats: ListPaginationChatEntity;
    waitingChats: ListPaginationChatEntity;
  }) {
    Object.assign(this, {userChats, waitingChats});
  }
}
