import {injectable} from "inversify";
import {configure, makeObservable} from "mobx";

configure({
    enforceActions: "always",
});

@injectable()
export class CommonController {


    constructor() {
        makeObservable(this);
    }

}
