import {injectable} from "inversify";
import {ListPaginationEntity} from "@dropDesk/domain/entities/common/list_pagination.entity";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import {Observable} from "rxjs";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {VoidSuccess} from "@dropDesk/domain/entities/response/response";
import {ChatIntegrationConnectionEntity} from "@dropDesk/domain/entities/chat/chat_integration_connection.entity";
import {CancelTokenSource} from "axios";
import {AllChatsEntity} from "@dropDesk/domain/entities/chat/all_chats.entity";

@injectable()
export abstract class ChatRepository {
    public abstract listChatsAttending(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>>;

    public abstract listChatsWaiting(searchParam: string, page: number): Promise<ListPaginationEntity<TicketEntity>>;

    public abstract subscribe(handleDisconnect: (temporary: boolean) => void, getLastUpdates: () => void, id?: string, retry?: boolean, useSocket?: boolean, ticket?: TicketEntity | null): Observable<TicketEntity[]>;

    public abstract loadMessages(chatId: string, page: number, cancelTokenSource?: CancelTokenSource): Promise<ListPaginationEntity<TicketMessageEntity>>;

    public abstract listChatMessagesAfterSubscription(chatId: string): Promise<TicketMessageEntity[]>;

    public abstract startConnection(): Promise<ChatIntegrationConnectionEntity>;

    public abstract logOut(): Promise<VoidSuccess>;

    public abstract connectionState(): Promise<ChatIntegrationConnectionEntity>;

    public abstract subscribeConnection(
        id: string,
        useSocket: boolean,
        handleDisconnect: (temporary: boolean) => void,
        getLastUpdates: () => void
    ): Observable<ChatIntegrationConnectionEntity | null>;

    public abstract sendMessage(message: TicketMessageEntity, associateSenderToTicket: boolean): Promise<TicketMessageEntity>;

    public abstract delete(id: string): Promise<void>;

    public abstract subscriptionUploadFile(message: TicketMessageEntity): Observable<TicketMessageEntity>;

    public abstract editMessage(idMessage: string, text: string): Promise<void>;

    public abstract navigateToMessage(id: string, ticketNumber: number): Promise<ListPaginationEntity<TicketMessageEntity>>;

    public abstract reactMessage(
        messageId: string,
        reactionId: string,
        reaction: string,
    ): Promise<VoidSuccess>;

    public abstract informReadMessages(ticketId: string, cancelTokenSource?: CancelTokenSource): Promise<void>;

    public abstract clearTicketsCache(): void;

    public abstract joinTicket(ticketId: string): void;

    public abstract leaveTicket(ticketId: string): void;

    public abstract listAllChats(searchParam: string, cancelTokenSource?: CancelTokenSource): Promise<AllChatsEntity>;

    public abstract updateCacheFromSingleTicket(ticket: TicketEntity): void;

    public abstract getTicketFromCache(id: string): TicketEntity | undefined;

}
