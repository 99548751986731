import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";

@injectable()
export class GetTicketFromCacheUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(id: string): TicketEntity | undefined {
        return this._repository.getTicketFromCache(id);
    }
}

