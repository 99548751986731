export class ClientCreatAccountContactEntity {
    id!: string;
    idCompany!: string;
    name!: string;
    email!: string;
    password?: string;

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['password'];
        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    constructor(
        {
            name,
            email,
            password,
            id,
            idCompany
        }: {
            name: string;
            email: string;
            password?: string;
            id: string;
            idCompany: string;
        }) {
        Object.assign(this, {
            name,
            email,
            password,
            id,
            idCompany
        });
    }

    copyWith({
                 name,
                 email,
                 password,
                 id,
                 idCompany
             }: {
        name?: string;
        email?: string;
        password?: string;
        id?: string;
        idCompany?: string;
    }): ClientCreatAccountContactEntity {
        return new ClientCreatAccountContactEntity({
            name: name ?? this.name,
            email: email ?? this.email,
            password: password ?? this.password,
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
        })
    }
}

export class ClientCreateAccountEntity {
    companySponsorReference?: number;
    companyName!: string;
    contact!: ClientCreatAccountContactEntity;
    contract!: boolean;

    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['contract'];
        const client = this.copyWith({
            contact: this.contact.toJson() as any as ClientCreatAccountContactEntity
        });
        const json = JSON.parse(JSON.stringify(client));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    constructor(
        {
            companySponsorReference,
            companyName,
            contact,
            contract,
        }: {
            companySponsorReference?: number;
            companyName: string;
            contact: ClientCreatAccountContactEntity;
            contract: boolean;

        }) {
        Object.assign(this, {
            companySponsorReference,
            companyName,
            contact,
            contract,
        });
    }

    copyWith({
                 companySponsorReference,
                 companyName,
                 contact,
                 contract,
             }: {
        companySponsorReference?: number;
        companyName?: string;
        contact?: ClientCreatAccountContactEntity;
        contract?: boolean;

    }): ClientCreateAccountEntity {
        return new ClientCreateAccountEntity({
            companySponsorReference: companySponsorReference ?? this.companySponsorReference,
            companyName: companyName ?? this.companyName,
            contact: contact ?? this.contact,
            contract: contract ?? this.contract,
        })
    }
}
