import "reflect-metadata";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";
import {inject, injectable} from "inversify";
import {AuthRemoteDataSource} from "@dropDesk/data/data_source/auth/auth_remote.datasource";
import {ServerError, SignInError} from "@dropDesk/domain/entities/exceptions/exceptions";
import firebaseAuth from "firebase/auth";
import {TwoFactorAuthResponse, VoidSuccess} from "@dropDesk/domain/entities/response/response";
import {MailAction} from "@dropDesk/domain/entities/common/actions_entity";

@injectable()
export class AuthRepositoryImpl implements AuthRepository {
    private _dataSource: AuthRemoteDataSource;

    constructor(@inject(AuthRemoteDataSource) dataSource: AuthRemoteDataSource) {
        this._dataSource = dataSource;
    }

    public getCurrentUser(): Promise<UserEntity | null> {
        try {
            return this._dataSource.getCurrentUser();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    async login(email: string, password: string): Promise<VoidSuccess> {
        try {
            return await this._dataSource.login(email, password);
        } catch (e: any) {
            throw new SignInError(e.message);
        }
    }

    public migrate(email: string, password: string, token: string): Promise<UserEntity> {
        try {
            return this._dataSource.migrate(email, password, token);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public logout(): Promise<void> {
        try {
            return this._dataSource.logout();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public logoutByAdmin(idUser: string): Promise<Record<string, boolean>> {
        try {
            return this._dataSource.logoutByAdmin(idUser);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public recoveryAccount(email: string): Promise<void> {
        try {
            return this._dataSource.recoveryAccount(email);
        } catch (e: any) {
            throw new SignInError(e.message);
        }
    }

    public createUser(email: string, password: string): Promise<firebaseAuth.UserCredential> {
        try {
            return this._dataSource.createUser(email, password);
        } catch (e: any) {
            throw new SignInError(e.message);
        }
    }

    public getByToken(): Promise<UserEntity | null> {
        try {
            return this._dataSource.getByToken();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public loginServer(token?: string): Promise<VoidSuccess> {
        try {
            return this._dataSource.loginServer(token);
        } catch (e: any) {
            throw new ServerError(e.message);
        }

    }

    public resend2FA(email: string): Promise<void> {
        try {
            return this._dataSource.resend2FA(email);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public logoutServer(): Promise<Record<string, boolean>> {
        try {
            return this._dataSource.logoutServer();
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }

    public getUserByFirebaseAuth(): Promise<firebaseAuth.User | null> {
        return this._dataSource.getUserByFirebaseAuth();
    }

    public getUserByDecodedToken(): Record<string, unknown> | null {
        return this._dataSource.getUserByDecodedToken();
    }

    public startMigration(email: string, action?: MailAction): Promise<void> {
        try {
            return this._dataSource.startMigration(email, action);
        } catch (e: any) {
            throw new ServerError(e.message);
        }
    }
}
