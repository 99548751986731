import {injectable} from "inversify";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import firebaseAuth from "firebase/auth";
import {TwoFactorAuthResponse, VoidSuccess} from "@dropDesk/domain/entities/response/response";
import {MailAction} from "@dropDesk/domain/entities/common/actions_entity";

@injectable()
export abstract class AuthRepository {
    public abstract getByToken(): Promise<UserEntity | null>;

    public abstract createUser(email: string, password: string): Promise<firebaseAuth.UserCredential>;

    public abstract login(email: string, password: string): Promise<VoidSuccess>;

    public abstract logout(): Promise<void>;

    public abstract recoveryAccount(email: string): Promise<void>;

    public abstract getCurrentUser(): Promise<UserEntity | null>;

    public abstract loginServer(token?: string): Promise<VoidSuccess>;

    public abstract logoutServer(): Promise<Record<string, boolean>>;

    public abstract logoutByAdmin(idUser: string): Promise<Record<string, boolean>>;

    public abstract getUserByFirebaseAuth(): Promise<firebaseAuth.User | null>;

    public abstract getUserByDecodedToken(): Record<string, unknown> | null;

    public abstract startMigration(email: string, action?: MailAction): Promise<void>;

    public abstract migrate(email: string, password: string, token: string): Promise<UserEntity>;

    public abstract resend2FA(email: string): Promise<void>;
}

