import {CompanyEntity} from "../company/company.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {SectorUsersEntity} from "@dropDesk/domain/entities/sectors_users/sectors_users.entity";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import {ThemeEntity} from "@dropDesk/domain/entities/theme/theme.entity";
import {
    InfoOrigin,
    InfoOriginDetail,
    InfoOriginDetailToTranslate,
    InfoOriginToTranslate
} from "@dropDesk/domain/entities/generic/info_origin.enum";

export class UserExtraInfoEntity {
    permissionJustListUserTicket?: boolean;

    public static fromJson(json: Record<string, any>): UserExtraInfoEntity {
        return new UserExtraInfoEntity({
            permissionJustListUserTicket: (json['permissionJustListUserTicket']) ?? false,
        });
    }

    constructor({permissionJustListUserTicket}: { permissionJustListUserTicket?: boolean }) {
        Object.assign(this, {
            permissionJustListUserTicket
        });
    }

    copyWith({
                 permissionJustListUserTicket
             }: {
        permissionJustListUserTicket?: boolean
    }): UserExtraInfoEntity {
        return new UserExtraInfoEntity({
            permissionJustListUserTicket: permissionJustListUserTicket ?? this.permissionJustListUserTicket,
        })
    }

}

export class UserEntity {
    id!: string;
    idCompany!: string;
    company!: CompanyEntity;
    name!: string;
    email?: string | null;
    userMain!: boolean;
    superAdmin!: boolean;
    telephone?: string | null;
    permissionAdmin!: boolean;
    permissionScreenReports!: boolean;
    permissionScreenUsers!: boolean;
    permissionScreenWarnings!: boolean;
    permissionScreenClients!: boolean;
    permissionScreenSectors!: boolean;
    permissionCancelTicket!: boolean;
    permissionDeleteData!: boolean;
    isMyContact?: boolean | null;
    deleted!: boolean;
    fcmToken?: Record<string, unknown> | null;
    urlImageProfile?: string | null;
    role!: UserRole;
    origin!: InfoOrigin;
    originDetail?: InfoOriginDetail;
    idClient?: string | null;
    client?: ClientEntity | null;
    identity?: string | null;
    createdAt?: string;
    password?: string | null;
    sectors!: SectorUsersEntity[] | [];
    action?: BackendAction | null;
    blocked?: boolean;
    isCreateAccount?: boolean;
    hasAccountCreated?: boolean;
    renewToken?: string;
    renewedAt?: string;
    verified?: boolean;
    theme!: string;
    themeObject?: ThemeEntity;
    extraInfo?: UserExtraInfoEntity;

    public isUnsaved(): boolean {
        return !this.createdAt;
    }

    static fromJson(json: Record<string, any>): UserEntity {
        return new UserEntity({
            id: json['id'] as string,
            idCompany: json['idCompany'] as string,
            company: json['company'] ? CompanyEntity.fromJson(json['company']) : undefined,
            name: json['name'] as string,
            email: json['email'] ? json['email'] as string : null,
            userMain: (json['userMain'] as boolean | null) ?? false,
            superAdmin: (json['superAdmin'] as boolean | null) ?? false,
            telephone: json['telephone'] ? json['telephone'] as string : null,
            permissionAdmin: (json['permissionAdmin'] as boolean | null) ?? false,
            permissionScreenReports:
                (json['permissionScreenReports'] as boolean | null) ?? false,
            permissionScreenUsers:
                (json['permissionScreenUsers'] as boolean | null) ?? false,
            permissionScreenWarnings:
                (json['permissionScreenWarnings'] as boolean | null) ?? false,
            permissionScreenClients:
                (json['permissionScreenClients'] as boolean | null) ?? false,
            permissionScreenSectors:
                (json['permissionScreenSectors'] as boolean | null) ?? false,
            permissionCancelTicket:
                (json['permissionCancelTicket'] as boolean | null) ?? false,
            permissionDeleteData:
                (json['permissionDeleteData'] as boolean | null) ?? false,
            blocked: (json['blocked'] as boolean | null) ?? false,
            isMyContact: (json['isMyContact'] as boolean | null) ?? false,
            deleted: (json['deleted'] as boolean | null) ?? false,
            urlImageProfile: json['urlImageProfile'] ? json['urlImageProfile'] as string : null,
            role: json['role'] as UserRole,
            origin: json['origin'] as InfoOrigin,
            originDetail: json['originDetail'] as InfoOriginDetail,
            idClient: json['idClient'] as string | null,
            client: json['client'] ? ClientEntity.fromJson(json['client']) : null,
            identity: json['identity'] as string | null,
            createdAt: json['createdAt'] as string | undefined,
            sectors: json['sectors'] ? (json['sectors'] as Record<string, any>[]).map((json) => SectorUsersEntity.fromJson(json)) : [],
            renewToken: json['renewToken'] as string | undefined,
            renewedAt: json['renewedAt'] as string | undefined,
            verified: (json['verified'] as boolean | null) ?? false,
            theme: (json['theme'] as string | null) ?? 'light-green',
            extraInfo: json['extraInfo'] ? UserExtraInfoEntity.fromJson(json['extraInfo']) : undefined,
            isCreateAccount: (json['isCreateAccount']) ?? false,
            themeObject: (json['themeObject']) ?? undefined,
            action: json['action']
        });
    }

    public imagePathReference(): string {
        return `companies/${this.idCompany}${RoutesEnum.users}/${this.id}/${this.id}.png`;
    }

    public get getOrigin(): string {
        return `criado via ${InfoOriginToTranslate[this.origin as keyof typeof InfoOriginToTranslate]} ${this.originDetail ? ` através do ${InfoOriginDetailToTranslate[this.originDetail as keyof typeof InfoOriginDetailToTranslate]}` : ""}`;
    }

    public get isUserClient(): boolean {
        return this.role === UserRole.userClient;
    }

    public get isAttendant(): boolean {
        return this.role === UserRole.attendant;
    }


    public toJson(): Record<string, unknown> {
        const ignorePropsList: string[] = ['hasAccountCreated', 'password',
            'company', 'client', 'themeObject',];

        const json = JSON.parse(JSON.stringify(this.copyWith(this)));
        for (const key of ignorePropsList) {
            delete json[key];
        }

        return json;
    }

    public toCreateAccount(): Record<string, unknown> {
        return {
            name: this.name,
            email: this.email
        }
    }


    constructor({
                    id,
                    idCompany,
                    company,
                    name,
                    email,
                    action,
                    userMain,
                    superAdmin,
                    telephone,
                    permissionAdmin,
                    permissionScreenReports,
                    permissionScreenUsers,
                    permissionScreenWarnings,
                    permissionScreenClients,
                    permissionScreenSectors,
                    permissionCancelTicket,
                    permissionDeleteData,
                    isMyContact,
                    deleted,
                    fcmToken,
                    urlImageProfile,
                    role,
                    origin,
                    originDetail,
                    idClient,
                    client,
                    identity,
                    createdAt,
                    password,
                    sectors,
                    isCreateAccount,
                    hasAccountCreated,
                    blocked,
                    renewToken,
                    renewedAt,
                    verified,
                    theme,
                    themeObject,
                    extraInfo
                }: {
        id: string;
        idCompany: string;
        company?: CompanyEntity;
        name: string;
        email?: string | null;
        userMain: boolean;
        superAdmin: boolean;
        telephone?: string | null;
        permissionAdmin?: boolean;
        permissionScreenReports: boolean;
        permissionScreenUsers: boolean;
        permissionScreenWarnings: boolean;
        permissionScreenClients: boolean;
        permissionScreenSectors: boolean;
        permissionCancelTicket: boolean;
        permissionDeleteData: boolean;
        blocked?: boolean;
        isMyContact?: boolean | null;
        deleted: boolean;
        fcmToken?: Record<string, unknown> | null;
        urlImageProfile?: string | null;
        role: UserRole;
        origin: InfoOrigin;
        originDetail?: InfoOriginDetail;
        idClient?: string | null;
        client?: ClientEntity | null;
        identity?: string | null;
        createdAt?: string;
        password?: string | null;
        action?: BackendAction | null;
        sectors: SectorUsersEntity[] | [];
        isCreateAccount?: boolean;
        hasAccountCreated?: boolean;
        renewToken?: string;
        renewedAt?: string;
        verified?: boolean;
        themeObject?: ThemeEntity;
        theme: string;
        extraInfo?: UserExtraInfoEntity;
    }) {
        Object.assign(this, {
            id,
            idCompany,
            company,
            name,
            email,
            userMain,
            superAdmin,
            telephone,
            permissionAdmin,
            permissionScreenReports,
            permissionScreenUsers,
            permissionScreenWarnings,
            permissionScreenClients,
            permissionScreenSectors,
            permissionCancelTicket,
            permissionDeleteData,
            isMyContact,
            deleted,
            action,
            fcmToken,
            urlImageProfile,
            role,
            origin,
            originDetail,
            idClient,
            client,
            identity,
            createdAt,
            password,
            sectors,
            isCreateAccount,
            hasAccountCreated,
            blocked,
            renewToken,
            renewedAt,
            verified,
            theme,
            themeObject,
            extraInfo
        });
    }

    copyWith({
                 id,
                 idCompany,
                 company,
                 name,
                 email,
                 userMain,
                 superAdmin,
                 telephone,
                 permissionAdmin,
                 permissionScreenReports,
                 permissionScreenUsers,
                 permissionScreenWarnings,
                 permissionScreenClients,
                 permissionScreenSectors,
                 permissionCancelTicket,
                 permissionDeleteData,
                 isMyContact,
                 deleted,
                 fcmToken,
                 urlImageProfile,
                 role,
                 origin,
                 originDetail,
                 idClient,
                 client,
                 identity,
                 createdAt,
                 password,
                 action,
                 sectors,
                 replaceTelephoneIfNull,
                 replaceFcmTokenIfNull,
                 replaceUrlImageProfileIfNull,
                 replaceIdClientIfNull,
                 replaceClientIfNull,
                 replaceIdentityIfNull,
                 replaceEmailIfNull,
                 replaceIsMyContactIfNull,
                 replacePasswordIfNull,
                 replaceActionIfNull,
                 removeImage,
                 isCreateAccount,
                 hasAccountCreated,
                 blocked,
                 renewToken,
                 renewedAt,
                 verified,
                 theme,
                 themeObject,
                 extraInfo
             }: {
        id?: string;
        idCompany?: string;
        company?: CompanyEntity;
        name?: string;
        email?: string | null;
        userMain?: boolean;
        superAdmin?: boolean;
        telephone?: string | null;
        permissionAdmin?: boolean;
        permissionScreenReports?: boolean;
        permissionScreenUsers?: boolean;
        permissionScreenWarnings?: boolean;
        permissionScreenClients?: boolean;
        permissionScreenSectors?: boolean;
        permissionCancelTicket?: boolean;
        permissionDeleteData?: boolean;
        isMyContact?: boolean | null;
        deleted?: boolean;
        fcmToken?: Record<string, unknown> | null;
        urlImageProfile?: string | null;
        role?: UserRole;
        origin?: InfoOrigin;
        originDetail?: InfoOriginDetail;
        idClient?: string | null;
        client?: ClientEntity | null;
        identity?: string | null;
        createdAt?: string;
        password?: string | null;
        action?: BackendAction | null;
        sectors?: SectorUsersEntity[] | [];
        replacePasswordIfNull?: boolean | null,
        replaceAddressIfNull?: boolean | null,
        replaceTelephoneIfNull?: boolean | null,
        replaceFcmTokenIfNull?: boolean | null,
        replaceUrlImageProfileIfNull?: boolean | null,
        replaceIdClientIfNull?: boolean | null,
        replaceClientIfNull?: boolean | null,
        replaceIdentityIfNull?: boolean | null,
        replaceEmailIfNull?: boolean | null,
        replaceIsMyContactIfNull?: boolean | null,
        replaceActionIfNull?: boolean | null;
        removeImage?: boolean,
        isCreateAccount?: boolean,
        hasAccountCreated?: boolean,
        blocked?: boolean;
        renewToken?: string;
        renewedAt?: string;
        verified?: boolean;
        theme?: string;
        themeObject?: ThemeEntity;
        extraInfo?: UserExtraInfoEntity;

    }): UserEntity {
        return new UserEntity({
            id: id ?? this.id,
            idCompany: idCompany ?? this.idCompany,
            company: company ?? this.company,
            name: name ?? this.name,
            email: replaceEmailIfNull ? email : this.email,
            userMain: userMain ?? this.userMain,
            superAdmin: superAdmin ?? this.superAdmin,
            telephone: replaceTelephoneIfNull ? telephone : this.telephone,
            permissionAdmin: permissionAdmin ?? this.permissionAdmin,
            permissionScreenReports: permissionScreenReports ?? this.permissionScreenReports,
            permissionScreenUsers: permissionScreenUsers ?? this.permissionScreenUsers,
            permissionScreenWarnings: permissionScreenWarnings ?? this.permissionScreenWarnings,
            permissionScreenClients: permissionScreenClients ?? this.permissionScreenClients,
            permissionScreenSectors: permissionScreenSectors ?? this.permissionScreenSectors,
            permissionCancelTicket: permissionCancelTicket ?? this.permissionCancelTicket,
            permissionDeleteData: permissionDeleteData ?? this.permissionDeleteData,
            isMyContact: replaceIsMyContactIfNull ? isMyContact : this.isMyContact,
            deleted: deleted ?? this.deleted,
            fcmToken: replaceFcmTokenIfNull ? fcmToken : this.fcmToken,
            urlImageProfile: removeImage ? null : replaceUrlImageProfileIfNull ? urlImageProfile : this.urlImageProfile,
            isCreateAccount: isCreateAccount ?? this.isCreateAccount,
            hasAccountCreated: hasAccountCreated ?? this.hasAccountCreated,
            role: role ?? this.role,
            origin: origin ?? this.origin,
            originDetail: originDetail ?? this.originDetail,
            idClient: replaceIdClientIfNull ? idClient : this.idClient,
            client: replaceClientIfNull ? client : this.client,
            identity: replaceIdentityIfNull ? identity : this.identity,
            createdAt: createdAt ?? this.createdAt,
            password: replacePasswordIfNull ? password : this.password,
            sectors: sectors ?? this.sectors,
            blocked: blocked ?? this.blocked,
            action: replaceActionIfNull ? action : this.action,
            renewToken: renewToken ?? this.renewToken,
            renewedAt: renewedAt ?? this.renewedAt,
            verified: verified ?? this.verified,
            theme: theme ?? this.theme,
            themeObject: themeObject ?? this.themeObject,
            extraInfo: extraInfo ?? this.extraInfo,
        })
    }
}

export class SimpleUserInfo {
    id!: string;
    idClient?: string;
    idCompany?: string;
    name!: string;
    urlImageProfile?: string;
    role!: UserRole;

    public get isAttendant(): boolean {
        return this.role === UserRole.attendant;
    }

    constructor({id, idClient, idCompany, name, urlImageProfile, role}: {
        id: string,
        idClient?: string,
        idCompany?: string,
        name: string,
        urlImageProfile?: string
        role: UserRole;
    }) {
        Object.assign(this, {
            id, idClient, idCompany, name, urlImageProfile, role
        });
    }
}

export class UserInfoEntity {
    email?: string;
    id?: string;
    idCompany?: string;
    role?: string;
    admin: boolean;

    constructor({
                    id,
                    idCompany,
                    email,
                    role,
                    admin
                }: {
        id?: string;
        idCompany?: string;
        email?: string;
        role?: string;
        admin: boolean;

    }) {
        this.id = id;
        this.idCompany = idCompany;
        this.email = email;
        this.role = role;
        this.admin = admin;
    }

    static isInputValid(json: Record<string, any>): boolean {
        return (
            json['firebaseId'] &&
            json['email'] &&
            json['id'] &&
            json['idCompany'] &&
            json['role']
        );
    }

    static fromJson(json: Record<string, any>): UserInfoEntity {
        return new UserInfoEntity({
            id: json['id'],
            idCompany: json['idCompany'],
            email: json['email'],
            role: json['role'],
            admin: json['admin']
        });
    }
}
