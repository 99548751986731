import {observer} from "mobx-react";
import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Container, ContainerButtonMoveScrollToBottom,
    ContainerChatMessageInput,
    ContainerScreenChat,
    ContentChatMessageAndInput, ContentLoading, Icon, LoadingMoreMessageBottom, LoadingMoreMessageTop,
} from "@dropDesk/presentation/components/chat_view/chat_message/styled";
import PickFileGeneric from "@dropDesk/presentation/components/drop_zone/pick_file_generic";
import InputMessage from "@dropDesk/presentation/components/chat_view/chat_message/input_message";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import MessagesBox from "@dropDesk/presentation/components/chat_view/chat_message/message_box";
import {AudioRecordEntity} from "@dropDesk/domain/entities/common/audio_record.entity";
import firebaseStorage from "firebase/storage";
import UploadTask = firebaseStorage.UploadTask;
import {ReplyEntity} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import ClipLoading from "@dropDesk/presentation/components/loadings/loading_clip";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";

export type ChildRefType = {
    handleAllChangesImperative: (message: ReplyEntity | null) => void;
    getCurrentMessage: () => TicketMessageEntity;
};

const ChatMessage = observer(
    ({
         handlePickFile,
         handleOnScrollTop,
         handleOnScrollBottom,
         compactModeMessages,
         userLogged,
         canChange,
         canSendMessage,
         canEditFile,
         userClient,
         attendant,
         messages,
         idMessageFocused,
         handleClickOnMessageReply,
         cancelUpload,
         onRetrySendMessageError,
         topMessageLoading,
         bottomMessageLoading,
         sendMessage,
         handleRemoveReaction,
         handleAddReaction,
         lastUsedReactions,
         sendAudio,
         handleDeleteMessage,
         handleEditMessage,
         handleResetMessageFocused,
         idCurrentTicket,
         handleMoveCursorToFinal,
         targetMessagePaginationTop,
         targetMessagePaginationBottom,
         targetMessageButtonNavigateBottom,
         allReactions,
         stopLoading,
         isMeAttendingTicket,
         ticketIsOpen,
         childRef,
         targetVirtualizedMessage,
         handleScrollTopVirtualizedMessage,
         showOriginalFile,
         setLoaded
     }: {
         handlePickFile: (files: File[]) => void
         handleOnScrollTop: () => Promise<boolean>
         handleOnScrollBottom: () => Promise<boolean>
         compactModeMessages: boolean,
         userLogged: UserEntity
         canChange: boolean,
         canSendMessage: boolean,
         canEditFile: boolean,
         userClient: UserEntity,
         attendant?: UserEntity,
         messages: TicketMessageEntity[],
         idMessageFocused: string | null
         handleClickOnMessageReply: (reply: ReplyEntity) => void
         cancelUpload: (uploadTask?: UploadTask) => void
         onRetrySendMessageError: (message: TicketMessageEntity) => void
         topMessageLoading: boolean
         bottomMessageLoading: boolean,
         sendMessage: (message: TicketMessageEntity) => Promise<void>
         handleRemoveReaction: (reaction: TicketMessageEntity, messageReacted: TicketMessageEntity) => Promise<void>
         handleAddReaction: (reaction: string, messageToReacted: TicketMessageEntity) => Promise<void>
         lastUsedReactions: string[]
         sendAudio: (message: TicketMessageEntity, audioRecorder: AudioRecordEntity) => void
         handleDeleteMessage: (message: TicketMessageEntity) => void
         handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
         handleResetMessageFocused: () => void
         handleMoveCursorToFinal: () => void
         idCurrentTicket: string
         targetMessagePaginationTop?: TicketMessageEntity
         targetMessagePaginationBottom?: TicketMessageEntity
         targetMessageButtonNavigateBottom?: TicketMessageEntity
         targetVirtualizedMessage: TicketMessageEntity | null
         allReactions: TicketMessageEntity[],
         stopLoading: () => void
         isMeAttendingTicket: boolean
         ticketIsOpen: boolean
         childRef: React.MutableRefObject<ChildRefType | null>
         handleScrollTopVirtualizedMessage: () => void
         showOriginalFile: (message: TicketMessageEntity) => void
         setLoaded: (message: TicketMessageEntity) => void
     }
    ) => {
        const appController = useInjection(AppController);
        const colors = appController.theme.colorScheme;
        const containerChatRef = useRef<HTMLDivElement>(null);
        const isDarkTheme = colors.brightness === Brightness.dark;
        const [showButtonMoveScrollToBottom, setShowButtonMoveScrollToBottom] = useState(false);
        useEffect(() => {
            if (targetMessagePaginationTop) {
                const messageTriggerTop = document.getElementById(targetMessagePaginationTop.id);
                const intersectionObserver = new IntersectionObserver(entries => {
                    if (entries.some(entry => (entry.isIntersecting || entry.boundingClientRect.bottom > 0))) {
                        handleOnScrollTop().then(() => stopLoading());
                    }
                })
                if (messageTriggerTop) intersectionObserver.observe(messageTriggerTop);
                return () => {
                    return intersectionObserver.disconnect();
                }

            }
        }, [targetMessagePaginationTop]);

        useEffect(() => {
            if (targetMessagePaginationBottom) {

                const messageTriggerBottom = document.getElementById(targetMessagePaginationBottom.id);
                const intersectionObserver = new IntersectionObserver(entries => {
                    if (entries.some(entry => (entry.isIntersecting || entry.boundingClientRect.top < 0))) {
                        loadMoreMessagesBottom();
                    }

                })
                if (messageTriggerBottom) intersectionObserver.observe(messageTriggerBottom);
                return () => {
                    return intersectionObserver.disconnect();
                }

            }
        }, [targetMessagePaginationBottom]);

        useEffect(() => {

            if (targetMessageButtonNavigateBottom) {
                const messageTriggerNavigateBottom = document.getElementById(targetMessageButtonNavigateBottom.id);
                const intersectionObserver = new IntersectionObserver(entries => {
                    if (entries.some(entry => !entry.isIntersecting)) {
                        setShowButtonMoveScrollToBottom(true);
                    } else {
                        setShowButtonMoveScrollToBottom(false);
                    }

                })
                if (messageTriggerNavigateBottom) intersectionObserver.observe(messageTriggerNavigateBottom);
                return () => {
                    return intersectionObserver.disconnect();
                }

            } else {
                setShowButtonMoveScrollToBottom(false);
            }
        }, [targetMessageButtonNavigateBottom]);

        useEffect(() => {
            if (targetVirtualizedMessage) {

                const messageTriggerVirtualizedMessage = document.getElementById(targetVirtualizedMessage.id);
                const intersectionObserver = new IntersectionObserver(entries => {
                    if (entries.some(entry => (entry.isIntersecting || entry.boundingClientRect.bottom > 0))) {
                        handleScrollTopVirtualizedMessage();
                    }

                })
                if (messageTriggerVirtualizedMessage) intersectionObserver.observe(messageTriggerVirtualizedMessage);
                return () => {
                    return intersectionObserver.disconnect();
                }

            }
        }, [targetVirtualizedMessage]);

        const moveScrollToBottom = () => {
            if (containerChatRef.current) {
                containerChatRef.current.scrollTop = 0;
            }
        };

        const loadMoreMessagesBottom = async () => {
            const lastMessage = messages[0];
            const lastElement = document.getElementById(lastMessage.id);
            const loadedMoreMessage = await handleOnScrollBottom();
            if (
                loadedMoreMessage &&
                lastElement &&
                containerChatRef.current &&
                containerChatRef.current.scrollTop === 0
            ) {
                lastElement.scrollIntoView({
                    block: 'end',
                    behavior: 'auto',
                });
            }
            stopLoading();
        }

        const setMessageToReply = (message: ReplyEntity | null): void => {
            if (childRef.current) {
                childRef.current.handleAllChangesImperative(message);
            }
        };

        const handleOnClickMoveCursorToFinal = () => {
            setShowButtonMoveScrollToBottom(false);
            moveScrollToBottom();
            handleMoveCursorToFinal();
        }

        const realMessages = useMemo(() => {
            if (targetVirtualizedMessage) {
                const index = messages.findIndex((message) => message.id === targetVirtualizedMessage.id);
                if (index > -1) {
                    return messages.slice(0, index + 1);
                }
                return messages;
            } else {
                return messages;
            }
        }, [messages, targetVirtualizedMessage]);

        return (
            <Container>
                <ContainerScreenChat background={"transparent"}>
                    <PickFileGeneric
                        disabled={!canEditFile}
                        maxFiles={20}
                        maxSizeInMegaBytes={16}
                        onPick={(files: File[]) => {
                            handlePickFile(files);
                        }}
                        noClick={true}
                        isMultiple={true}
                        noDrag={false}
                        renderComponent={
                            <ContainerChatMessageInput>

                                <ContentChatMessageAndInput
                                    ref={containerChatRef}
                                    bordercolor={colors.border}
                                >


                                    {bottomMessageLoading &&
                                        <ContentLoading>
                                            <LoadingMoreMessageBottom
                                                background={colors.delicateBackground}
                                                isDarkTheme={isDarkTheme}
                                                border={colors.border}
                                            >
                                                <ClipLoading color={colors.accent}/>
                                            </LoadingMoreMessageBottom>
                                        </ContentLoading>
                                    }
                                    <MessagesBox
                                        messages={realMessages}
                                        userClient={userClient}
                                        attendant={attendant}
                                        idMessageFocused={idMessageFocused}
                                        handleClickOnMessageReply={handleClickOnMessageReply}
                                        cancelUpload={(uploadTask) => cancelUpload(uploadTask)}
                                        onRetrySendMessageError={(message) => onRetrySendMessageError(message)}
                                        canChange={canChange}
                                        userLogged={userLogged}
                                        handleRemoveReaction={handleRemoveReaction}
                                        handleAddReaction={(reaction, message) => handleAddReaction(reaction, message)}
                                        lastUsedReactions={lastUsedReactions}
                                        handleEditMessage={handleEditMessage}
                                        handleDeleteMessage={handleDeleteMessage}
                                        setMessageToReply={setMessageToReply}
                                        handleResetMessageFocused={handleResetMessageFocused}
                                        compactModeMessages={compactModeMessages}
                                        idCurrentTicket={idCurrentTicket}
                                        allReactions={allReactions}
                                        isMeAttendingTicket={isMeAttendingTicket}
                                        ticketIsOpen={ticketIsOpen}
                                        showOriginalFile={showOriginalFile}
                                        setLoaded={setLoaded}
                                    />
                                    {topMessageLoading &&
                                        <ContentLoading>
                                            <LoadingMoreMessageTop
                                                background={colors.delicateBackground}
                                                isDarkTheme={isDarkTheme}
                                                border={colors.border}
                                            >
                                                <ClipLoading color={colors.accent}/>
                                            </LoadingMoreMessageTop>
                                        </ContentLoading>
                                    }
                                </ContentChatMessageAndInput>
                                {showButtonMoveScrollToBottom &&
                                    <ContainerButtonMoveScrollToBottom
                                        background={colors.accent}
                                        isDarkTheme={isDarkTheme}
                                        onClick={() => handleOnClickMoveCursorToFinal()}
                                    >
                                        <DropDeskIcon
                                            icon={ICONS_DROPDESK.downArrow}
                                            size={22}
                                            color={SharedColors.white}
                                            style={{marginTop: 3}}
                                        />
                                    </ContainerButtonMoveScrollToBottom>
                                }
                                {canSendMessage &&
                                    <InputMessage
                                        onSendMessage={sendMessage}
                                        canSendMessage={canSendMessage}
                                        handlePickFile={handlePickFile}
                                        userLogged={userLogged}
                                        sendAudio={sendAudio}
                                        ref={childRef}
                                        moveScrollToBottom={handleOnClickMoveCursorToFinal}
                                        idCurrentTicket={idCurrentTicket}
                                    />}

                            </ContainerChatMessageInput>
                        }
                    />
                </ContainerScreenChat>
            </Container>
        )
    })
;
export default ChatMessage;
