import React from 'react';
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {SpinStyled} from "@dropDesk/presentation/components/loadings/spin/styled";
import {SpinSize} from "antd/lib/spin";


export const SpinLoading = (
    {
        size,
        color,
        tip,
        children,
        spinning,
        style
    }: {
        size: SpinSize,
        color?: string
        tip?: string
        spinning?: boolean
        children?: React.ReactNode
        style?: React.CSSProperties
    }
) => {

    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;

    return (
        <SpinStyled
            color={color ?? colors.text}
            size={size}
            tip={tip}
            spinning={spinning}
            style={style}
        >
            {children}
        </SpinStyled>
    );

};
