import {injectable} from "inversify";
import {storage} from "@dropDesk/data/clients/firebase.client";
import {ref, deleteObject, uploadBytesResumable, getDownloadURL, uploadBytes} from "firebase/storage";
import {FileUpload} from "@dropDesk/domain/entities/common/file_upload";
import {Observable, Observer} from "rxjs";
import {UploadOptionsEntity} from "@dropDesk/domain/entities/ticket/message/upload_message.entity";

@injectable()
export abstract class RemoteStorageDatasource {
    public abstract uploadFile(file: File, path: string): Promise<string | null>;

    public abstract removeFile(path: string): Promise<void>;

    public abstract getDownloadUrl(ref: string): Promise<string | null>;

    public abstract uploadListFile(files: FileUpload[]): Promise<Array<string>>;

    public abstract uploadFileListener(path: string, uploadOptions: UploadOptionsEntity): Observable<UploadOptionsEntity>;

}

@injectable()
export class RemoteStorageDatasourceImpl implements RemoteStorageDatasource {
    private maxRetryAttempts = 1;
    private cacheControl = 'public, max-age=604800, immutable';

    public removeFile = async (path: string): Promise<void> => {
        return new Promise<void>(async (resolve, reject) => {
            try {
                const deleteRef = ref(storage, path);
                await deleteObject(deleteRef);
                return resolve();
            } catch (e) {
                return reject(e);
            }
        })
    }

    public uploadFileListener = (path: string, uploadOptions: UploadOptionsEntity): Observable<UploadOptionsEntity> => {
        return new Observable<UploadOptionsEntity>((observer: Observer<UploadOptionsEntity>) => {
            let attempt = 0;
            const startUpload = async () => {
                // await getTokenAppCheck();
                const refStorage = ref(storage, path);
                const reference = uploadBytesResumable(refStorage, uploadOptions.file.file, {cacheControl: this.cacheControl});
                reference.on('state_changed', (snapshot) => {

                        const progress = parseFloat(((snapshot.bytesTransferred / snapshot.totalBytes) * 100).toFixed(0));

                        observer.next(uploadOptions.copyWith({
                            progress,
                            referenceUploadTask: reference,
                        }));

                    },
                    async (error) => {
                        console.log('error.code', error.code);
                        if (error.code === 'storage/unauthenticated' && attempt < this.maxRetryAttempts) {
                            attempt++;
                            try {
                                //await getTokenAppCheck(true);
                                startUpload();
                            } catch (error) {
                                observer.next(uploadOptions.copyWith({
                                    referenceUploadTask: reference
                                }));

                                observer.error(error);
                            }
                        } else {
                            observer.next(uploadOptions.copyWith({
                                referenceUploadTask: reference
                            }));

                            observer.error(error);
                        }

                    },
                    async () => {
                        const urlDownload = await getDownloadURL(reference.snapshot.ref);
                        observer.next(uploadOptions.copyWith({
                            urlDownload,
                            progress: 100
                        }));
                        observer.complete();
                    }
                );
            }
            startUpload();
        });
    }

    public uploadFile = async (file: File, path: string): Promise<string> => {
        return new Promise<string>(async (resolve, reject) => {
            try {
                const refStorage = ref(storage, path);
                const reference = await uploadBytes(refStorage, file, {cacheControl: this.cacheControl});
                const downloadURL = await getDownloadURL(reference.ref);
                return resolve(downloadURL);
            } catch (e) {
                return reject(e);
            }
        });
    }

    public uploadListFile = async (files: FileUpload[]): Promise<Array<string>> => {
        return new Promise<Array<string>>(async (resolve, reject) => {
            try {
                const _response = await Promise.all(files.map(async (entry) => await this.uploadFile(entry.file, entry.path)));
                return resolve(_response);
            } catch (e) {
                return reject(e);
            }
        })
    }

    public getDownloadUrl = async (path: string): Promise<string | null> => {
        return new Promise<string | null>(async (resolve, reject) => {
            try {
                const refStorage = ref(storage, path);
                const downloadURL = await getDownloadURL(refStorage);
                return resolve(downloadURL);
            } catch (e: any) {
                if (e.code === 'storage/object-not-found') {
                    return resolve(null);
                }
                return reject(e);
            }
        })
    }
}
