export enum BackendAction {
  insert = 'insert',
  update = 'update',
  delete = 'delete',
  nil = 'nil',
}

export enum DeleteOrRestore {delete = 'delete', restore = 'restore'}

export enum MailAction {
  send = 'send',
  resend = 'resend',
}
