import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";

type PlaceHolderImageTyoe = {
    hint: string;
}
export const Container = styled.div`
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  height: max-content;
  overflow: hidden;
`;

export const ContainerTitle = styled.div`
  display: flex;
  width: calc(100vw - 258px);
  position: absolute;
  height: 46px;
  z-index: 1085;
  pointer-events: auto;
  align-items: center;
  justify-content: center;
`;

export const ContainerFooter = styled.div`
    display: flex;
    flex-direction: column;
    width: 100vw;
    position: absolute;
    height: calc(100vh - 46px);
    z-index: 1085;
    pointer-events: auto;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    color: ${SharedColors.white};
`;

export const ContentCaption = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const ContentDownloadButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 46px;
  max-height: 46px;
  width: 42px;
  max-width: 42px;
  z-index: 1085;
  margin-right: 12px;
  cursor: pointer;
`;

export const TextCaption = styled.span`
  color: ${SharedColors.white};
  font-size: 15px;
  text-align: center;
  font-weight: bold;
  z-index: 1085;
`;
export const PlaceHolderImage = styled.div<PlaceHolderImageTyoe>`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 80px;
  height: 100%;
  max-height: 190px;
  background: ${props => props.hint};
  border-radius: 7px;
`;
