import styled from "styled-components";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {hexToRGBA} from "@dropDesk/utils/helpers/colors";


export const Container = styled.div`
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  overflow: hidden;
  justify-content: center;
  border-radius: 5px;
  max-height: 500px;
`;

export const ContentImage = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    width: 300px;
    min-width: 300px;
    max-width: 300px;
    max-height: 300px;
    height: 300px;
    min-height: 300px;
    position: relative;
`;

export const OverlayImage = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 100px;
    padding: 8px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    background: linear-gradient(to top, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
    opacity: 1;
    transition: opacity 0.3s ease-in-out;
`;

export const ButtonPlay = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 3px;
    padding: 10px;
    background: transparent;
    border-radius: 50%;

    &:hover {
        border-color: transparent;
        outline: none;
        background-color: rgba(0, 0, 0, 0.7);
    }
`;

export const Seconds = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 13px;
    color: ${SharedColors.white};
`;
export const ContentFakeControls = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
`;


