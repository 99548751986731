import {BadgeStyled, Container, Content, ContentTabs, ContentTabsAndNotifications, Tab} from "./styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {observer} from "mobx-react";
import React, {useEffect, useState} from "react";
import Tabs, {Items} from "@dropDesk/presentation/components/tabs/background_style";
import {
    ActiveRouteEnum,
    ChatController,
} from "@dropDesk/presentation/pages/chat/controller/chat.controller";
import {ChatLists} from "@dropDesk/presentation/pages/chat/ui/chat_list";
import {TicketEntity} from "@dropDesk/domain/entities/ticket/ticket.entity";
import ContentChat from "@dropDesk/presentation/pages/chat/ui/content_chat";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {ColorScheme} from "@dropDesk/domain/entities/theme/color_scheme";
import {ContactsList} from "@dropDesk/presentation/pages/chat/ui/contact_list";
import {TabsChat} from "@dropDesk/domain/entities/chat/chat_enum";
import {ImageEditorController} from "@dropDesk/presentation/pages/image_editor/controller/image_editor.controller";
import {useParams} from "react-router-dom";
import {TicketController} from "@dropDesk/presentation/pages/ticket/controller/ticket.controller";
import OneSignal from "react-onesignal";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import SoundConfiguration from "@dropDesk/presentation/pages/chat/ui/sound_configuration";
import ModalReconnectSocket from "@dropDesk/presentation/components/modals/modal_reconnect_socket";
import toast_message from "@dropDesk/utils/toast_message/toast_message";
import ContentInfoDisconnectedSubscription from "src/presentation/components/content_info_disconnected_subscription";
import {CancelTokenManager} from "@dropDesk/data/clients/http.client";

const Chat = (observer(() => {

    const appController: AppController = useInjection(AppController);
    const colors: ColorScheme = appController.theme.colorScheme;
    const userLogged: UserEntity = appController.user!;
    const controller: ChatController = useInjection(ChatController);
    const ticketController: TicketController = useInjection(TicketController);
    const imageEditorController: ImageEditorController = useInjection(ImageEditorController);
    const {id} = useParams();
    const [chatParamId, setChatParamId] = useState<string | undefined>(undefined);
    const [visibleModalConnect, setVisibleModalConnect] = useState<boolean>(false);
    const [hasErrorConnectSocket, setHasErrorConnectSocket] = useState<boolean>(false);
    const [hasErrorConnectSocketTemporary, setHasErrorConnectSocketTemporary] = useState<boolean>(false);
    const [loadingReconnect, setLoadingReconnect] = useState<boolean>(false);
    const maxWidthContainer = 395;

    useEffect(() => {
        initialize().then();
        return () => {
            CancelTokenManager.cancelToken();
            OneSignal.User.PushSubscription.optIn();
            controller.removeAllValues();
            controller.dispose();
            imageEditorController.deleteAllCache();
            controller.stopNotificationWaiting();
        }
    }, []);

    useEffect(() => {
        controller.playAudioWaitingListDepending();
    }, [controller.tableListWaiting, controller.soundQueueWaitingChat]);

    useEffect(() => {
        if (chatParamId) {
            handleClickPushNotification(chatParamId).then();
        }
    }, [chatParamId]);

    useEffect(() => {
        if (id !== controller.currentChat?.id && chatInitialized()) {
            setChatParamId(id);
        }
    }, [id]);

    const initialize = async () => {
        try {
            const cancelTokenSource = CancelTokenManager.createNewToken();
            OneSignal.User.PushSubscription.optOut();
            await controller.initialize(
                userLogged, ActiveRouteEnum.chat, handleDisconnect, handleGetLastUpdates,
                undefined, undefined, undefined, cancelTokenSource
            );
            setChatParamId(id);
        } catch (error) {
            console.error(error);
        } finally {
            CancelTokenManager.clearToken();
        }
    }

    const handleDisconnect = (temporary: boolean) => {
        if (temporary) {
            setHasErrorConnectSocketTemporary(true);
        } else {
            setHasErrorConnectSocket(true);
            setVisibleModalConnect(true);
            setHasErrorConnectSocketTemporary(false);
        }
    }

    const handleClickPushNotification = async (id: string) => {
        let ticket = controller.tableListWaiting.data.find((ticket) => ticket.id === id) || controller.tableListChats.data.find((ticket) => ticket.id === id);
        if (ticket) {
            ticket = await ticketController.getTicket(id, false);
        }
        controller.handleClickPushNotification(ticket!).then();
    }

    const onSearch = async (searchParam: string, isChatAttending = true) => {
        if (isChatAttending) {
            await controller.listChatsAttending(searchParam ?? controller.searchParam);
        } else {
            await controller.listChatsWaiting(searchParam ?? controller.searchParam);
        }
    }

    function onClickTicket(ticket: TicketEntity) {
        if (ticket.id !== controller.currentChat?.id) {
            controller.setChat(ticket);
        }
    }

    const chatInitialized = (): boolean => {
        return controller.tableListChats.totalRows > 0 || controller.tableListWaiting.totalRows > 0;
    }
    const items: Array<Items> = [
        {
            label:

                <Tab width={userLogged.permissionScreenClients ? 126 : 185}>
                    <SoundConfiguration
                        text={`Notificação sonora ao receber mensagem`}
                        useSound={controller.soundNewMessageChat}
                        setSound={(value) => controller.setSoundNewMessageChat(value)}
                    />
                    ATENDENDO
                    {controller.tableListChats.totalRows > 0 && <BadgeStyled
                        colorcount={SharedColors.white}
                        bordercolor={colors.accent}
                        color={colors.accent}
                        style={{marginLeft: 5}}
                        count={controller.tableListChats.totalRows}
                        overflowCount={99}
                        textfontsizesm={15}
                        size="small"
                    />}
                </Tab>,
            key: TabsChat.attending,
            children: <ChatLists
                onClick={(ticket) => onClickTicket(ticket)}
                searchParam={controller.searchParam}
                tickets={controller.filteredListChat}
                loadMore={() => controller.getDataFromPageChatsAttending()}
                loadingMore={controller.loading && !controller.loadingMessage}
                loading={controller.loading}
                hasMore={controller.tableListChats.hasMore}
                useSearch={true}
                onSearch={onSearch}
                idTicketSelected={controller.currentChat?.id}
                userLogged={userLogged}
                disabled={imageEditorController.loading}
            />,
        },
        {
            label:
                <Tab width={userLogged.permissionScreenClients ? 126 : 185}>
                    <SoundConfiguration
                        text={`Notificação sonora na fila de espera`}
                        useSound={controller.soundQueueWaitingChat}
                        setSound={(value) => controller.setSoundQueueWaitChat(value)}
                    />
                    ESPERA
                    {controller.tableListWaiting.totalRows > 0 && <BadgeStyled
                        colorcount={SharedColors.white}
                        bordercolor={colors.accent}
                        color={colors.accent}
                        style={{marginLeft: 5}}
                        count={controller.tableListWaiting.totalRows}
                        overflowCount={99}
                        textfontsizesm={15}
                        size="small"
                    />}
                </Tab>,
            key: TabsChat.waiting,
            children: <ChatLists
                onClick={(ticket) => onClickTicket(ticket)}
                searchParam={controller.searchParam}
                tickets={controller.filteredListWaiting}
                loadMore={() => controller.getDataFromPageChatsWaiting()}
                loadingMore={controller.loading && !controller.loadingMessage}
                loading={controller.loading}
                hasMore={controller.tableListWaiting.hasMore}
                useSearch={true}
                onSearch={(searchParam) => onSearch(searchParam, false)}
                idTicketSelected={controller.currentChat?.id}
                userLogged={userLogged}
                disabled={imageEditorController.loading}
            />,
        },
        {
            label: <Tab width={126}>
                CONTATOS
            </Tab>,
            key: TabsChat.contacts,
            children: <ContactsList
                handleInitChat={(ticket) => controller.initChatDependantFromContact(ticket)}
                maxWidthContainer={maxWidthContainer}
            />,
        }];

    const handleReconnectSocket = async (): Promise<void> => {
        try {
            setLoadingReconnect(true);
            await initialize();
            await Promise.all([
                controller.loadStaticMessages(),
            ]);
            fetchTicket();
            setVisibleModalConnect(false);
            setHasErrorConnectSocket(false);
            setLoadingReconnect(false);
            setHasErrorConnectSocketTemporary(false);
        } catch (e) {
            setHasErrorConnectSocket(true);
            setLoadingReconnect(false);
            toast_message.error('Não foi possível estabelecer a conexão. Por favor, tente novamente.' +
                ' Se o problema persistir, entre em contato com a equipe da DropDesk');
        }
    }

    const handleGetLastUpdates = async (): Promise<void> => {
        await Promise.all([
            controller.listAllChats(controller.searchParam),
            controller.loadStaticMessages(),
        ]);
        fetchTicket();
        setHasErrorConnectSocketTemporary(false);
    }

    const fetchTicket = (): void => {
        if (controller.currentChat) {
            const ticket = controller.getTicketFromCache(controller.currentChat.id);
            if (ticket) {
                controller.setChat(ticket!, false);
            }
        }
    }


    return (
        <Container background={colors.onBackground}>
            <Content>
                <ContentTabsAndNotifications>
                    <ContentTabs width={maxWidthContainer}>
                        {(hasErrorConnectSocket || hasErrorConnectSocketTemporary) &&
                            <ContentInfoDisconnectedSubscription
                                loading={loadingReconnect}
                                onClickReconnect={() => handleReconnectSocket()}
                                visibleTemporaryInformation={hasErrorConnectSocketTemporary}
                            />}
                        <Tabs
                            defaultActiveKey={controller.activeTabChat}
                            activeKey={controller.activeTabChat}
                            onChange={(key) => controller.setActiveTabChat(key as TabsChat)}
                            items={userLogged.permissionScreenClients ? items : items.filter((entry) => entry.key !== 'contacts')}
                        />
                    </ContentTabs>

                    <ContentChat
                        chatId={controller.currentChat?.id}
                        widthContainerListChats={maxWidthContainer}
                    />

                    <ModalReconnectSocket
                        loading={controller.loading}
                        onRequestClose={() => setVisibleModalConnect(false)}
                        onRequestSave={() => handleReconnectSocket()}
                        visible={visibleModalConnect}
                    />

                </ContentTabsAndNotifications>

            </Content>

        </Container>
    );
}));

export default Chat;
