import "reflect-metadata";
import {inject, injectable} from "inversify";
import {AuthRepository} from "@dropDesk/domain/repositories/auth/auth.repository";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";

@injectable()
export class MigrateUseCase {
    private _repository: AuthRepository;

    constructor(
        @inject(AuthRepository) repository: AuthRepository
    ) {
        this._repository = repository;
    }

    public call(email: string, password: string, token: string): Promise<UserEntity> {
        return this._repository.migrate(email, password, token);
    }
}
