import {MessageMediaBase} from "@dropDesk/domain/entities/ticket/message/external/message_media_base";
import {FileMessageEntity} from "@dropDesk/domain/entities/common/file_message";
import {SimpleUserInfo, UserEntity} from "@dropDesk/domain/entities/user/user.entity";

export enum MessageStatus {
    ERROR = 0,
    PENDING = 1,
    SERVER_ACK = 2,
    DELIVERY_ACK = 3,
    READ = 4,
    PLAYED = 5,
}

export enum MessageChatType {
    textMessage = 'conversation',
    protocolMessage = 'protocolMessage',//verificar qual tipo de mensagem é
    imageMessage = 'imageMessage',
    videoMessage = 'videoMessage',
    documentMessage = 'documentMessage',
    locationMessage = 'locationMessage',
    stickerMessage = 'stickerMessage',
    audioMessage = 'audioMessage',
    reactionMessage = 'reactionMessage',
    contactMessage = 'contactMessage',
    systemMessage = 'systemMessage',
    viewOnceMessage = 'viewOnceMessage',
    unknown = 'unknown'
}

export class ReplyEntity {
    messageId!: string;
    quotedConversationType!: MessageChatType;
    quotedMessage!: Record<string, any> | null;
    participantId?: string;
    participant?: SimpleUserInfo;

    public makeParticipantReplyMessage(user: SimpleUserInfo): ReplyEntity {
        return new ReplyEntity({
            ...this,
            participant: user
        })
    }

    public get getText(): string {
        if (this.quotedMessage && this.quotedConversationType === MessageChatType.textMessage) {
            return this.quotedMessage.conversation;
        }
        return '';
    }

    public get socialMessage(): string {

        switch (this.quotedConversationType) {
            case MessageChatType.imageMessage:
                return (!!this.quotedMessage && !!this.quotedMessage.caption) ? `📷 Foto (${this.quotedMessage.caption})` : "📷 Foto";

            case MessageChatType.stickerMessage:
                return (!!this.quotedMessage && !!this.quotedMessage.caption) ? `📷 Figurinha (${this.quotedMessage.caption})` : "📷 Figurinha";

            case MessageChatType.audioMessage:
                return (!!this.quotedMessage && !!this.quotedMessage.caption) ? `🎤 Mensagem de voz (${this.quotedMessage.caption})` : "🎤 Mensagem de voz";

            case MessageChatType.videoMessage:
                return (!!this.quotedMessage && !!this.quotedMessage.caption) ? `📹 Vídeo (${this.quotedMessage.caption})` : "📹 Vídeo";

            case MessageChatType.locationMessage:
                return "📍Localização";

            case MessageChatType.contactMessage:
                return "👤 Contato";

            case MessageChatType.documentMessage:
                return (!!this.quotedMessage && !!this.quotedMessage.caption) ? `📁 Arquivo (${this.quotedMessage.caption})` : "📁 Arquivo";

            case MessageChatType.viewOnceMessage:
                return 'Mensagem particular';

            default:
                return this.getText;
        }
    }

    constructor({
                    messageId,
                    quotedConversationType,
                    quotedMessage,
                    participantId,
                    participant
                }: {
        messageId: string;
        quotedConversationType: MessageChatType;
        quotedMessage: Record<string, any> | null;
        participantId?: string;
        participant?: SimpleUserInfo;
    }) {
        Object.assign(this, {
            messageId,
            quotedConversationType,
            quotedMessage,
            participantId,
            participant
        });
    }
}

export class TextMessageEntity {
    conversation!: string;

    constructor({conversation}: { conversation: string }) {
        Object.assign(this, {
            conversation,
        });
    }

    public get toReply(): TextMessageEntity {
        return new TextMessageEntity({
            conversation: this.conversation
        })
    }

}

export class SystemMessageEntity {
    conversation!: string;

    constructor({conversation}: { conversation: string }) {
        Object.assign(this, {
            conversation,
        });
    }
}

export class ContactMessageEntity {
    displayName!: string;
    vcard!: string;

    constructor({displayName, vcard}: { displayName: string; vcard: string }) {
        Object.assign(this, {
            displayName,
            vcard,
        });
    }
}

export class ReactionMessageEntity {
    text!: string;
    messageReactedId!: string;
    fromMe!: boolean;
    participantId!: string;

    constructor({
                    text,
                    messageReactedId,
                    fromMe,
                    participantId,
                }: {
        text: string;
        messageReactedId: string;
        fromMe: boolean;
        participantId: string;
    }) {
        Object.assign(this, {
            text,
            messageReactedId,
            fromMe,
            participantId,
        });
    }
}

export class StickerMessageEntity extends MessageMediaBase {
    height!: number;
    width!: number;
    isAnimated!: boolean;
    isAvatar!: boolean;

    constructor({
                    mimetype,
                    buffer,
                    height,
                    width,
                    length,
                    caption,
                    jpegThumbnail,
                    isAnimated,
                    isAvatar,
                }: {
        mimetype: string;
        buffer?: string;
        length: number;
        caption: string | null;
        jpegThumbnail: string | undefined;
        height: number;
        width: number;
        isAnimated: boolean;
        isAvatar: boolean;
    }) {
        super({
            mimetype,
            buffer,
            length,
            caption,
            jpegThumbnail,
        });
        Object.assign(this, {
            height,
            width,
            isAnimated,
            isAvatar,
        });
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: this.caption,
            jpegThumbnail: this.jpegThumbnail,
        })
    }
}

export class DocumentMessageEntity extends MessageMediaBase {
    title!: string;
    pageCount?: number | null;
    fileName!: string | null;

    constructor({
                    mimetype,
                    buffer,
                    length,
                    caption,
                    jpegThumbnail,
                    title,
                    pageCount,
                    fileName
                }: {
        mimetype: string;
        buffer?: string;
        length: number;
        caption: string | null;
        jpegThumbnail: string | undefined;
        title: string;
        pageCount: number | null;
        fileName: string | null;
    }) {
        super({
            mimetype,
            buffer,
            length,
            caption,
            jpegThumbnail,
        });
        Object.assign(this, {
            title,
            pageCount,
            fileName
        });
    }

    static fromFile(fileMessage: FileMessageEntity): DocumentMessageEntity {
        return new DocumentMessageEntity({
            jpegThumbnail: undefined,
            pageCount: 0,
            title: fileMessage.file.name,
            mimetype: fileMessage.file.type,
            buffer: undefined,
            length: fileMessage.file.size,
            caption: fileMessage.description ?? null,
            fileName: fileMessage.file.name,
        })
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: this.caption,
            jpegThumbnail: this.jpegThumbnail,
        })
    }
}

export class VideoMessageEntity extends MessageMediaBase {
    height!: number;
    width!: number;
    seconds!: number;
    gifPlayback!: boolean;

    constructor({
                    mimetype,
                    buffer,
                    height,
                    width,
                    length,
                    seconds,
                    caption,
                    jpegThumbnail,
                    gifPlayback,
                }: {
        mimetype: string;
        buffer?: string;
        height: number;
        width: number;
        length: number;
        seconds: number;
        caption: string | null;
        jpegThumbnail: string | undefined;
        gifPlayback: boolean;
    }) {
        super({
            mimetype,
            buffer,
            length,
            caption,
            jpegThumbnail,
        });
        Object.assign(this, {
            height,
            width,
            seconds,
            gifPlayback,
        });


    }


    static fromFile(fileMessage: FileMessageEntity): VideoMessageEntity {
        return new VideoMessageEntity({
            jpegThumbnail: undefined,
            mimetype: fileMessage.file.type,
            buffer: undefined,
            length: fileMessage.file.size,
            caption: fileMessage.description ?? null,
            height: 0,
            width: 0,
            seconds: 0,
            gifPlayback: false,
        })
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: this.caption,
            jpegThumbnail: this.jpegThumbnail,
        })
    }

}

export class AudioMessageEntity extends MessageMediaBase {
    seconds!: number;
    ptt!: boolean;

    constructor({
                    mimetype,
                    buffer,
                    length,
                    seconds,
                    ptt,
                }: {
        mimetype: string;
        buffer?: string;
        length: number;
        seconds: number;
        ptt: boolean;
    }) {
        super({
            mimetype,
            buffer,
            length,
            caption: null,
            jpegThumbnail: undefined,
        });
        Object.assign(this, {
            seconds,
            ptt,
        });
    }

    static fromFile(fileMessage: FileMessageEntity): AudioMessageEntity {
        return new AudioMessageEntity({
            mimetype: fileMessage.file.type,
            buffer: undefined,
            length: fileMessage.file.size,
            seconds: 0,
            ptt: true,
        })
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: null,
            jpegThumbnail: undefined,
        })
    }
}

export class ImageMessageEntity extends MessageMediaBase {
    height!: number;
    width!: number;

    constructor({
                    mimetype,
                    buffer,
                    height,
                    width,
                    length,
                    caption,
                    jpegThumbnail,
                }: {
        mimetype: string;
        buffer?: string;
        height: number;
        width: number;
        length: number;
        caption: string | null;
        jpegThumbnail: string | undefined;
    }) {
        super({
            mimetype,
            buffer,
            length,
            caption,
            jpegThumbnail,
        });
        Object.assign(this, {
            height,
            width,
        });
    }

    static fromFile(fileMessage: FileMessageEntity): ImageMessageEntity {
        return new ImageMessageEntity({
            jpegThumbnail: undefined,
            mimetype: fileMessage.file.type,
            buffer: undefined,
            length: fileMessage.file.size,
            caption: fileMessage.description ?? null,
            height: 0,
            width: 0,
        })
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: this.caption,
            jpegThumbnail: this.jpegThumbnail,
        })
    }
}

export class LocationMessageEntity extends MessageMediaBase {
    degreesLatitude!: number;
    degreesLongitude!: number;
    name?: string | null;
    address?: string | null;
    url?: string | null;

    constructor({
                    degreesLatitude,
                    degreesLongitude,
                    jpegThumbnail,
                    name,
                    address,
                    url,
                }: {
        degreesLatitude: number;
        degreesLongitude: number;
        jpegThumbnail: string | undefined;
        name: string | null;
        address: string | null;
        url: string | null;
    }) {
        super({
            mimetype: '',
            buffer: undefined,
            caption: null,
            length: 0,
            jpegThumbnail: jpegThumbnail,
        });
        Object.assign(this, {
            degreesLatitude,
            degreesLongitude,
            jpegThumbnail,
            name,
            address,
            url,
        });
    }

    public get toReply(): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: this.mimetype,
            buffer: undefined,
            length: this.length,
            caption: this.caption,
            jpegThumbnail: this.jpegThumbnail,
        })
    }
}

export class PlaySoundEntity {
    idTicket?: string;
    playSound?: boolean;

    constructor({
                    idTicket,
                    playSound,
                }: {
        idTicket?: string;
        playSound?: boolean;
    }) {
        Object.assign(this, {
            idTicket,
            playSound,
        });
    }

}

export const getMessageTypeFromFile = (fileMessage: FileMessageEntity): MessageChatType => {
    const type: string = fileMessage.file['type'].split('/')[0];
    const types: Record<string, MessageChatType> = {
        'video': MessageChatType.videoMessage,
        'image': MessageChatType.imageMessage,
    }
    return types[type] ? types[type] : MessageChatType.documentMessage;
}
