import {
    TicketMessageEntity,
} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import React, {useMemo} from "react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import MessageBox from "@dropDesk/presentation/components/messages/message_box";
import firebaseStorage from "firebase/storage";
import {
    ReplyEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import TicketHeader from "@dropDesk/presentation/components/chat_view/chat_message/message_box/ticket_header";
import UploadTask = firebaseStorage.UploadTask;
import {
    useCanChangeReaction, useDeleteMessageButton, useEditMessageButton,
    useGetStatus,
    useImagesMessage, useReactionButton, useReplyButton
} from "@dropDesk/presentation/components/chat_view/chat_message/message_box/use_message_action";
import {observer} from "mobx-react";

const MessagesBox =
    observer(({
                  messages,
                  userClient,
                  attendant,
                  idMessageFocused,
                  handleClickOnMessageReply,
                  cancelUpload,
                  onRetrySendMessageError,
                  canChange,
                  userLogged,
                  handleRemoveReaction,
                  handleAddReaction,
                  lastUsedReactions,
                  setMessageToReply,
                  handleDeleteMessage,
                  handleEditMessage,
                  handleResetMessageFocused,
                  compactModeMessages,
                  idCurrentTicket,
                  allReactions,
                  isMeAttendingTicket,
                  ticketIsOpen,
                  showOriginalFile,
                  setLoaded
              }: {
                  messages: TicketMessageEntity[],
                  userClient: UserEntity,
                  attendant?: UserEntity,
                  idMessageFocused: string | null
                  handleClickOnMessageReply: (reply: ReplyEntity) => void
                  cancelUpload: (uploadTask?: UploadTask) => void
                  onRetrySendMessageError: (message: TicketMessageEntity) => void
                  canChange: boolean
                  userLogged: UserEntity
                  handleRemoveReaction: (reaction: TicketMessageEntity, messageReacted: TicketMessageEntity) => Promise<void>
                  handleAddReaction: (reaction: string, messageToReacted: TicketMessageEntity) => Promise<void>
                  lastUsedReactions: string[]
                  setMessageToReply: (message: ReplyEntity | null) => void
                  handleDeleteMessage: (message: TicketMessageEntity) => void
                  handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
                  handleResetMessageFocused: () => void
                  compactModeMessages: boolean,
                  idCurrentTicket: string,
                  allReactions: TicketMessageEntity[]
                  isMeAttendingTicket: boolean
                  ticketIsOpen: boolean
                  showOriginalFile: (message: TicketMessageEntity) => void
                  setLoaded: (message: TicketMessageEntity) => void
              }
    ) => {

        const messageBoxProps = useMemo(() => {
            return messages.map((message) => ({
                message,
                useReplyButton: useReplyButton(message, canChange, idCurrentTicket, isMeAttendingTicket, ticketIsOpen, userLogged),
                useDeleteMessageButton: useDeleteMessageButton(message, canChange, userLogged, idCurrentTicket),
                status: useGetStatus(message, userClient, userLogged, idCurrentTicket, attendant),
                useEditMessageButton: useEditMessageButton(message, canChange, userLogged, idCurrentTicket),
                canRemoveReaction: useCanChangeReaction(message, canChange, idCurrentTicket),
                reactionButton: useReactionButton(message, canChange, idCurrentTicket, isMeAttendingTicket, userLogged),
                imageMessages: useImagesMessage(messages),
                reactions: allReactions.filter(entry => (entry.reactionMessage!.messageReactedId === message.idExternal || entry.reactionMessage!.messageReactedId === message.id) && !entry.deleted) ?? []
            }));
        }, [messages, userClient, userLogged, canChange, idCurrentTicket, isMeAttendingTicket, ticketIsOpen, allReactions, attendant]);
        return (
            <>
                {messageBoxProps.map(({
                                          message,
                                          useReplyButton,
                                          useDeleteMessageButton,
                                          status,
                                          useEditMessageButton,
                                          canRemoveReaction,
                                          reactionButton,
                                          imageMessages,
                                          reactions
                                      }) => (
                    <div key={message.id} id={message.id}>
                        {((message.isFirstPosition || message.isUniquePosition) && message.ticket) &&
                            <TicketHeader ticket={message.ticket} isInit={true}/>}
                        <MessageBox
                            message={message}
                            useReplyButton={useReplyButton}
                            onReplyClick={() => setMessageToReply(message.toReplyEntity)}
                            useDeleteMessageButton={useDeleteMessageButton}
                            resetMessageFocused={() => setTimeout(() => handleResetMessageFocused(), 1500)}
                            focus={!!idMessageFocused && (idMessageFocused === message.idExternal || idMessageFocused === message.id)}
                            onReplyMessageClick={(messageReply) => handleClickOnMessageReply(messageReply)}
                            onRetrySendMessageError={(message) => onRetrySendMessageError(message)}
                            status={status}
                            cancelUpload={(uploadTask) => cancelUpload(uploadTask)}
                            useEditMessageButton={useEditMessageButton}
                            canRemoveReaction={canRemoveReaction}
                            handleRemoveReaction={handleRemoveReaction}
                            handleAddReaction={handleAddReaction}
                            lastUsedReactions={lastUsedReactions}
                            userClient={userClient}
                            handleDeleteMessage={handleDeleteMessage}
                            handleEditMessage={handleEditMessage}
                            compactMode={compactModeMessages}
                            reactions={reactions}
                            useReactionButton={reactionButton}
                            attendant={attendant}
                            imageMessages={message.isValidPreviewMessage ? imageMessages : []}
                            showOriginalFile={showOriginalFile}
                            setLoaded={setLoaded}
                        />
                        {((message.isLastPosition || message.isUniquePosition) && message.ticket) &&
                            <TicketHeader ticket={message.ticket} isInit={false}/>}
                    </div>
                ))}

            </>
        )
    });
export default MessagesBox;
