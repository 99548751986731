import {ClientEntity} from "@dropDesk/domain/entities/client/client.entity";
import {ClientJuridicalNature} from "@dropDesk/domain/entities/client/client_enum";
import {AddressEntity} from "@dropDesk/domain/entities/common/address.entity";
import {generateUUIDV4} from "@dropDesk/utils/uuidv4/uuidv4";
import {BackendAction} from "@dropDesk/domain/entities/common/actions_entity";
import {getIdCompanyByLocalStorage} from "@dropDesk/utils/helpers/common";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {UserRole} from "@dropDesk/domain/entities/user/user_enum";
import {InfoOrigin} from "@dropDesk/domain/entities/generic/info_origin.enum";
import {
    ClientCreatAccountContactEntity,
    ClientCreateAccountEntity
} from "@dropDesk/domain/entities/client/client_create_account_entity";

export const newClient = () => new ClientEntity({
    id: '',
    idCompany: getIdCompanyByLocalStorage(),
    name: '',
    document: '',
    email: '',
    telephone: null,
    origin: InfoOrigin.system,
    urlImageProfile: null,
    deleted: false,
    users: [],
    socialName: '',
    juridicalNature: ClientJuridicalNature.juridical,
    friendlyName: '',
    addresses: [],
});

export const newAddress = () => new AddressEntity({
    id: generateUUIDV4(),
    zipCode: '',
    street: '',
    number: '',
    neighborhood: '',
    city: '',
    state: '',
    shortState: '',
    alias: null,
    complement: null,
    pin: null,
    action: BackendAction.insert,
});

export const newUserClient = (): UserEntity => new UserEntity({
    permissionCancelTicket: false,
    permissionDeleteData: false,
    permissionScreenClients: false,
    permissionScreenReports: false,
    permissionScreenSectors: false,
    permissionScreenUsers: false,
    permissionScreenWarnings: false,
    id: generateUUIDV4(),
    idCompany: getIdCompanyByLocalStorage(),
    name: '',
    permissionAdmin: false,
    deleted: false,
    role: UserRole.userClient,
    createdAt: undefined,
    userMain: false,
    superAdmin:false,
    isMyContact: false,
    action: BackendAction.insert,
    origin: InfoOrigin.system,
    theme: 'light-green',
    sectors: []
});

export const newClientCreateAccount = (): ClientCreateAccountEntity => new ClientCreateAccountEntity({
    companySponsorReference: undefined,
    companyName: '',
    contact: new ClientCreatAccountContactEntity({
        id: generateUUIDV4(),
        idCompany: getIdCompanyByLocalStorage(),
        name: '',
        email: '',
        password: '',
    }),
    contract: false
});
