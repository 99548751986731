import {Route, Routes} from "react-router-dom";
import CreateCompany from "@dropDesk/presentation/pages/company/ui/index";
import Dashboard from "@dropDesk/presentation/pages/dashboard/ui/index";
import NotFound from "@dropDesk/presentation/pages/notfound/ui/index";
import Chat from "@dropDesk/presentation/pages/chat/ui/index";
import Tickets from "@dropDesk/presentation/pages/ticket/ui/index";
import Clients from "@dropDesk/presentation/pages/client/ui/my_clients";
import Sectors from "@dropDesk/presentation/pages/sector/ui/index";
import Users from "@dropDesk/presentation/pages/user/ui/index";
import PrivateRoute from "./private_route";
import Login from "../pages/login/ui";
import FormClients from "@dropDesk/presentation/pages/client/ui/my_clients/form_client";
import FormUsers from "@dropDesk/presentation/pages/user/ui/form_user";
import FormSectors from "@dropDesk/presentation/pages/sector/ui/form_sector";
import Notices from "@dropDesk/presentation/pages/notices/ui";
import FormNotices from "@dropDesk/presentation/pages/notices/ui/form_notices";
import Configuration from "@dropDesk/presentation/pages/company_configurations/ui/index";
import ReportTicketsRate
    from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/report_ticket_rate_aggregate/ui";
import FormEvaluations from "@dropDesk/presentation/pages/ticket/evaluation/ui/form_evaluation";
import FormCreateTicket from "@dropDesk/presentation/pages/ticket/ui/form_create";
import ChatView from "@dropDesk/presentation/components/chat_view/chat_view";
import ChangePassword from "@dropDesk/presentation/pages/change_password/ui";
import Welcome from "@dropDesk/presentation/pages/welcome/ui";
import CompanyActiveConnections from "@dropDesk/presentation/pages/company_active_connections";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";
import ImportClients from "@dropDesk/presentation/pages/client/ui/import";
import FormImports from "@dropDesk/presentation/pages/client/ui/import/form_import";
import ExportDataClient from "@dropDesk/presentation/pages/client/ui/export";
import ExportDataUser from "@dropDesk/presentation/pages/user/ui/export";
import ExportDataSector from "@dropDesk/presentation/pages/sector/ui/export";
import Contacts from "@dropDesk/presentation/pages/client/ui/contacts";
import ExportContacts from "@dropDesk/presentation/pages/client/ui/contacts/export";
import ExportDataTicket from "@dropDesk/presentation/pages/reports/report_ticket_init/report_ticket/ui/export_excel";
import Subscription from "@dropDesk/presentation/pages/subscription/ui";
import React from "react";
import SelectRole from "@dropDesk/presentation/pages/select_role/ui";
import Profile from "@dropDesk/presentation/pages/profile";
import ConfirmAccount from "@dropDesk/presentation/pages/confirm_account/ui";
import ConnectionsExceeded from "@dropDesk/presentation/pages/connections_exceeded/ui";
import SubscriptionExpired from "@dropDesk/presentation/pages/subscription_expired_default_user/ui";
import InvoiceCheck from "@dropDesk/presentation/pages/subscription/invoice_check/ui";
import ReportTicketInit from "@dropDesk/presentation/pages/reports/report_ticket_init/ui";
import ReportTicketEvaluationInit from "@dropDesk/presentation/pages/reports/report_ticket_aggs_init/ui";


export default function RoutesApp() {

    return (
        <Routes>
            <Route path={RoutesEnum.login} element={<Login/>}/>
            <Route path={RoutesEnum.loginDefault} element={<Login/>}/>
            <Route path={RoutesEnum.companyCreate} element={<CreateCompany/>}/>
            <Route path={RoutesEnum.changePassword} element={<ChangePassword/>}/>
            <Route path={RoutesEnum.firstAccess} element={<ChangePassword/>}/>
            <Route path={RoutesEnum.migrated} element={<ChangePassword/>}/>
            <Route path={RoutesEnum.welcome} element={<Welcome/>}/>
            <Route path={RoutesEnum.role} element={<SelectRole/>}/>
            <Route path={RoutesEnum.confirmAccount} element={<ConfirmAccount/>}/>
            <Route path={RoutesEnum.connectionsExceeded} element={<ConnectionsExceeded/>}/>
            <Route path={RoutesEnum.subscriptionExpired} element={<SubscriptionExpired/>}/>
            <Route
                path={RoutesEnum.dashboard}
                element={
                    <PrivateRoute path={RoutesEnum.dashboard} element={<Dashboard/>}>
                        <Dashboard/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.connectionsExceeded}
                element={
                    <PrivateRoute path={RoutesEnum.connectionsExceeded} element={<ConnectionsExceeded/>}>
                        <ConnectionsExceeded/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.profile}
                element={
                    <PrivateRoute path={RoutesEnum.profile} element={<Profile/>}>
                        <Profile/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.chat}/:id?`}
                element={
                    <PrivateRoute path={RoutesEnum.chat} element={<Chat/>}>
                        <Chat/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.invoices}/:idCompany?/:idSubscription?/:idPayment?`}
                element={
                    <PrivateRoute path={RoutesEnum.invoices} element={<InvoiceCheck/>}>
                        <InvoiceCheck/>
                    </PrivateRoute>
                }
            />

            <Route
                path={RoutesEnum.tickets}
                element={
                    <PrivateRoute path={RoutesEnum.tickets} element={<Tickets/>}>
                        <Tickets/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.ticketsView}/:id`}
                element={
                    <PrivateRoute element={<ChatView/>}>
                        <ChatView/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.tickets}/:id`}
                element={
                    <PrivateRoute element={<FormCreateTicket/>}>
                        <FormCreateTicket/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.clients}
                element={
                    <PrivateRoute element={<Clients/>}>
                        <Clients/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.clients}/:id`}
                element={
                    <PrivateRoute element={<FormClients/>}>
                        <FormClients/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.subscription}
                element={
                    <PrivateRoute element={<Subscription/>}>
                        <Subscription/>
                    </PrivateRoute>
                }
            />

            <Route
                path={RoutesEnum.importclients}
                element={
                    <PrivateRoute element={<ImportClients/>}>
                        <ImportClients/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.importclients}/:id`}
                element={
                    <PrivateRoute element={<FormImports/>}>
                        <FormImports/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.exportclients}`}
                element={
                    <PrivateRoute element={<ExportDataClient/>}>
                        <ExportDataClient/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.exportcontacts}`}
                element={
                    <PrivateRoute element={<ExportContacts/>}>
                        <ExportContacts/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.contacts}`}
                element={
                    <PrivateRoute element={<Contacts/>}>
                        <Contacts/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.users}
                element={
                    <PrivateRoute element={<Users/>}>

                        <Users/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.exportusers}`}
                element={
                    <PrivateRoute element={<ExportDataUser/>}>
                        <ExportDataUser/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.users}/:id`}
                element={
                    <PrivateRoute element={<FormUsers/>}>
                        <FormUsers/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.activeConnections}
                element={
                    <PrivateRoute element={<CompanyActiveConnections/>}>

                        <CompanyActiveConnections/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.sectors}
                element={
                    <PrivateRoute element={<Sectors/>}>
                        <Sectors/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.exportsectors}`}
                element={
                    <PrivateRoute element={<ExportDataSector/>}>
                        <ExportDataSector/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.sectors}/:id`}
                element={
                    <PrivateRoute element={<FormSectors/>}>
                        <FormSectors/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.notices}
                element={
                    <PrivateRoute element={<Notices/>}>

                        <Notices/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.notices}/:id`}
                element={
                    <PrivateRoute element={<FormNotices/>}>
                        <FormNotices/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.reporttickets}
                element={
                    <PrivateRoute element={<ReportTicketInit/>}>
                        <ReportTicketInit/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.exportreporttickets}`}
                element={
                    <PrivateRoute element={<ExportDataTicket/>}>
                        <ExportDataTicket/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.reportticketsrate}
                element={
                    <PrivateRoute element={<ReportTicketsRate/>}>
                        <ReportTicketsRate/>
                    </PrivateRoute>
                }
            />
            <Route
                path={RoutesEnum.reportrate}
                element={
                    <PrivateRoute element={<ReportTicketEvaluationInit/>}>
                        <ReportTicketEvaluationInit/>
                    </PrivateRoute>
                }
            />
            <Route
                path={`${RoutesEnum.reportrate}/:id`}
                element={
                    <PrivateRoute element={<FormEvaluations/>}>
                        <FormEvaluations/>
                    </PrivateRoute>
                }
            />

            <Route
                path={`${RoutesEnum.configuration}/*`}
                element={
                    <PrivateRoute element={<Configuration/>}>
                        <Configuration/>
                    </PrivateRoute>
                }
            />

            <Route path='*' element={<NotFound/>}/>
        </Routes>
    );
}
