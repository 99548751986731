export class MessageMediaBase {
    mimetype!: string;
    buffer?: string;
    length!: number;
    caption!: string | null;
    jpegThumbnail!: string | undefined;

    constructor({
                    mimetype,
                    buffer,
                    length,
                    caption,
                    jpegThumbnail,
                }: {
        mimetype: string;
        buffer?: string;
        length: number;
        caption: string | null;
        jpegThumbnail: string | undefined;
    }) {
        Object.assign(this, {
            mimetype,
            buffer,
            length,
            caption,
            jpegThumbnail,
        });
    }

    public static fromJson(json: Record<string, any>): MessageMediaBase {
        return new MessageMediaBase({
            mimetype: json['mimetype'],
            buffer: json['buffer'],
            length: json['length'],
            caption: json['caption'],
            jpegThumbnail: json['jpegThumbnail'],
        });
    }


}
