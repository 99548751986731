import React, {useMemo, useRef, useState} from "react";
import {Image} from 'antd';
import {
    Container, ContainerFooter,
    ContainerTitle,
    ContentCaption,
    ContentDownloadButton,
    PlaceHolderImage,
    TextCaption
} from "@dropDesk/presentation/components/messages/message_box/image_message/styled";
import LinkFy from "@dropDesk/presentation/components/linkify";
import corruptFile from "@dropDesk/storage/images/corrupt-file.png"
import {downloadFile} from "@dropDesk/utils/helpers/files";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import './override_styles_image_preview.css';
import {SpinLoading} from "@dropDesk/presentation/components/loadings/spin";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import ClipLoading from "@dropDesk/presentation/components/loadings/loading_clip";

const ImageMessage = (
    {
        color,
        width,
        alt,
        height,
        hint,
        message,
        imageMessages,
        showOriginalFile
    }: {
        color: string,
        width: number;
        height?: number;
        alt?: string;
        hint: string;
        message: TicketMessageEntity
        imageMessages: TicketMessageEntity[]
        showOriginalFile: (message: TicketMessageEntity) => void
    }) => {

    const [isPreviewVisible, setPreviewVisible] = useState(false);
    const [current, setCurrent] = useState<number | undefined>(undefined);
    const [isLoading, setIsLoading] = useState(true);


    const currentImage = (): TicketMessageEntity | null => {
        if (!imageMessages) {
            return null;
        }
        return current !== null && current !== undefined ? imageMessages[current] : null;
    }

    const handleShowOriginalFile = (index: number): void => {
        if (imageMessages[index]) {
            if (!imageMessages[index]?.showOriginal) {
                setIsLoading(true);
            }
            imageMessages[index] = TicketMessageEntity.fromJson({
                ...imageMessages[index],
                showOriginal: true
            });
            showOriginalFile(imageMessages[index]);
        }
    }

    return (
        <Container>
            <Image
                preview={{
                    visible: false,
                    onVisibleChange: (visible, prevVisible) => {
                        const current = imageMessages.findIndex(image => image.id === message.id);
                        if (current !== null && current !== undefined && current > -1) {
                            setCurrent(current);
                            setPreviewVisible(true);
                            handleShowOriginalFile(current);
                        }
                    },
                }}
                placeholder={(
                    <PlaceHolderImage hint={hint}>
                        <SpinLoading
                            color={color}
                            size={"default"}
                        />
                    </PlaceHolderImage>
                )}
                width={width}
                src={message.realUrl}
                fallback={corruptFile}
                style={{border: 0, maxHeight: height ?? 200, height: height ?? undefined}}
                alt={alt ?? "Imagem"}
            />

            {isPreviewVisible && <div style={{display: 'none'}}>
                <Image.PreviewGroup
                    preview={{
                        visible: isPreviewVisible,
                        onVisibleChange: (visible) => {
                            setPreviewVisible(visible);
                        },
                        keyboard: true,
                        title: (
                            <ContainerTitle>
                                <ContentCaption>
                                    {currentImage()?.imageMessage?.caption &&
                                        < TextCaption>{currentImage()?.imageMessage?.caption}</TextCaption>}
                                </ContentCaption>
                                <>
                                    <TextCaption
                                        style={{
                                            marginRight: 10,
                                            minWidth: 46
                                        }}>{(current ?? 0) + 1}/{imageMessages?.length}</TextCaption>
                                    <ContentDownloadButton onClick={() => {
                                        if (currentImage()?.urlDownload) {
                                            downloadFile(currentImage()!.urlDownload!, message.getFileName);
                                        }
                                    }}>
                                        <DropDeskIcon
                                            icon={ICONS_DROPDESK.download}
                                            size={16}
                                            style={{marginTop: -3, cursor: 'pointer'}}
                                            color={SharedColors.white}
                                        />
                                    </ContentDownloadButton>
                                </>
                            </ContainerTitle>),
                        footer: (
                            <>
                                {isLoading && <ContainerFooter>
                                    <ClipLoading
                                        color={SharedColors.white}
                                        size={36}
                                    />
                                    <span style={{marginTop: 10}}>Carregando imagem ...</span>
                                </ContainerFooter>}
                            </>
                        ),
                        countRender: (_nextCurrent: number, total: number) => {
                            const nextCurrent = _nextCurrent - 1;
                            if (isPreviewVisible && nextCurrent !== current && nextCurrent > -1) {
                                setTimeout(() => {
                                    setCurrent(nextCurrent);
                                    handleShowOriginalFile(nextCurrent);
                                }, 0);
                            }
                            return ``;
                        },
                        current,
                    }}
                >
                    {imageMessages?.map((message) => {
                        const currentMessage: TicketMessageEntity | undefined = Number.isFinite(current) ? imageMessages[current!] : undefined;
                        return (
                            <Image
                                key={message.id}
                                src={currentMessage?.id === message.id ? message.urlDownload : undefined}
                                onLoad={() => {
                                    setIsLoading(false);
                                }}
                                fallback={corruptFile}
                            />
                        )
                    })}
                </Image.PreviewGroup>
            </div>}
            {message?.imageMessage?.caption && <LinkFy text={message?.imageMessage?.caption} color={color}/>}
        </Container>
    );
};

export default React.memo(ImageMessage);
