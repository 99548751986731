import {initializeApp} from "firebase/app";
import {getAnalytics} from "firebase/analytics";
import {getAuth} from "firebase/auth";
import {getStorage} from "firebase/storage";
import {getRemoteConfig} from "firebase/remote-config";

import {
    initializeAppCheck,
    ReCaptchaEnterpriseProvider,
    getToken as appCheckGetToken,
} from "firebase/app-check";
import {firebaseRemoteConfigDefaultsValue} from "@dropDesk/data/clients/firebase_remote_configs_client";

declare global {
    var FIREBASE_APPCHECK_DEBUG_TOKEN: string | undefined | boolean;
}

const firebaseConfig = {
    apiKey: process.env.FIREBASE_API_KEY,
    authDomain: process.env.FIREBASE_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_PROJECT_ID,
    storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
    messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
    appId: process.env.FIREBASE_APP_ID,
    measurementId: process.env.FIREBASE_MEASUREMENT_ID
};

const firebaseConfigLegacy = {
    apiKey: process.env.FIREBASE_LEGACY_API_KEY,
    authDomain: process.env.FIREBASE_LEGACY_AUTH_DOMAIN,
    projectId: process.env.FIREBASE_LEGACY_PROJECT_ID,
    appId: process.env.FIREBASE_LEGACY_APP_ID,
    measurementId: process.env.FIREBASE_LEGACY_MEASUREMENT_ID
};

const siteKey = process.env.APP_CHECK_KEY as string;
const app = initializeApp(firebaseConfig, "default");
const appLegacy = initializeApp(firebaseConfigLegacy, "legacy");

if (process.env.MODE === 'development') {
    self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.APP_CHECK_DEBUG_TOKEN;
}

// const appCheck = initializeAppCheck(app, {
//     provider: new ReCaptchaEnterpriseProvider(siteKey),
//     isTokenAutoRefreshEnabled: true // Set to true to allow auto-refresh.
// });

const analytics = getAnalytics(app);
const remoteConfig = getRemoteConfig(app);
remoteConfig.settings.minimumFetchIntervalMillis = process.env.MODE === 'development' ? 5000 : 3600000;
remoteConfig.defaultConfig = firebaseRemoteConfigDefaultsValue;

const auth = getAuth(app);
const authLegacy = getAuth(appLegacy);
const storage = getStorage(app);


// const getTokenAppCheck = async (forceRefresh = false) => {
//     try {
//         const tokenResult = await appCheckGetToken(appCheck, forceRefresh);
//         return tokenResult.token;
//     } catch (error) {
//         console.log('Erro ao atualizar o token de App Check:');
//         console.log(error);
//     }
// };

export {auth, storage, remoteConfig, authLegacy};
