import "reflect-metadata";
import {inject, injectable} from "inversify";
import {ChatRepository} from "@dropDesk/domain/repositories/chat/chat.repository";
import {AllChatsEntity} from "@dropDesk/domain/entities/chat/all_chats.entity";
import {CancelTokenSource} from "axios";

@injectable()
export class ListAllChatsChatUseCase {
    private _repository: ChatRepository;

    constructor(@inject(ChatRepository) repository: ChatRepository) {
        this._repository = repository;
    }

    public call(searchParam: string, cancelTokenSource?: CancelTokenSource): Promise<AllChatsEntity> {
        return this._repository.listAllChats(searchParam, cancelTokenSource);
    }
}

