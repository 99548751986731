import {
    ContainerEditMessage,
    Content,
    ContentMessageBox,
    Footer
} from "./styled";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import HeaderModal from "@dropDesk/presentation/components/headers/header_modal";
import React, {useEffect, useRef, useState} from "react";
import {Brightness} from "@dropDesk/domain/entities/theme/colors.entity";
import {Watermark} from "@dropDesk/presentation/pages/ticket/ui/styled";
import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import MessageBox from "@dropDesk/presentation/components/messages/message_box";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {Container, ContentInput} from "@dropDesk/presentation/components/chat_view/chat_message/input_message/styled";
import InputChat from "@dropDesk/presentation/components/inputs/input_chat";
import SendButton from "@dropDesk/presentation/components/chat_view/chat_message/input_message/send_button";
import {observer} from "mobx-react";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ContainerButtonInput} from "@dropDesk/presentation/components/chat_view/chat_message/styled";

const EditMessage = observer((
    {
        message,
        onClose,
        userClient,
        attendant,
        userLogged,
        onSend
    }: {
        message: TicketMessageEntity
        onClose: () => void
        userClient: UserEntity
        attendant?: UserEntity,
        userLogged: UserEntity
        onSend: (editText: string, currentMessage: TicketMessageEntity) => void
    }
) => {

    useEffect(() => {
        setEditText(message.getTextEditable);
        inputRef?.current?.focus();
    }, []);


    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const inputRef = useRef<HTMLInputElement>(null);
    const [editText, setEditText] = useState<string>('');

    const onKeyDown = (event: React.KeyboardEvent<Element>) => {
        const enterPressed = event.key === "Enter";
        const shiftPressed = event.shiftKey;

        if (enterPressed && !shiftPressed) {
            event.preventDefault();
            if (!!editText) {
                onSend(editText, message);
            }
        }

    }
    return (
        <ContainerEditMessage>
            <HeaderModal
                disabled={false}
                letter={"Editar mensagem"}
                nameIcon={ICONS_DROPDESK.close}
                onClick={onClose}
                background={colors.backgroundChatBox}
            />
            <Content>
                <Watermark isDarkTheme={colors.brightness === Brightness.dark} background={colors.backgroundChatBox}>
                    <ContentMessageBox>
                        <MessageBox
                            message={message}
                            useReplyButton={false}
                            onReplyClick={() => {
                            }}
                            useDeleteMessageButton={false}
                            handleDeleteMessage={() => {
                            }}
                            resetMessageFocused={() => {
                            }}
                            focus={false}
                            onReplyMessageClick={() => {
                            }}
                            onRetrySendMessageError={() => {
                            }}
                            status={message.getStatus(userClient, userLogged.role, attendant)}
                            cancelUpload={() => {
                            }}
                            handleRemoveReaction={async () => {

                            }}
                            canRemoveReaction={false}
                            useEditMessageButton={false}
                            handleEditMessage={() => {
                            }}
                            handleAddReaction={async () => {

                            }}
                            lastUsedReactions={[]}
                            isEdit={true}
                            reactions={[]}
                            showOriginalFile={() => {}}
                            setLoaded={() => {}}
                        />
                    </ContentMessageBox>
                </Watermark>
            </Content>
            <Footer background={colors.backgroundChatBox}>
                <Container
                    background={colors.backgroundChatBox}
                    useInModal={true}
                    isUserClient={appController.isUserClient}
                    shadow={SharedColors.black}
                    border={colors.border}
                >
                    <ContentInput style={{paddingLeft: 10, background: colors.backgroundInputChatBox, borderRadius: 8}}>
                        <InputChat
                            autoFocus={true}
                            onChange={(event) => setEditText(event.target.value)}
                            onKeyDown={(event) => onKeyDown(event)}
                            value={editText}
                            reference={inputRef}
                            minHeight={40}
                            maxHeight={150}
                            placeholder={"Digite sua mensagem aqui"}
                            visibleInput={true}
                            rightButtons={
                                <ContainerButtonInput
                                    accent={colors.accent}
                                    background={colors.buttonOnBackground}
                                >
                                    <SendButton
                                        onClickSend={() => onSend(editText, message)}/>
                                </ContainerButtonInput>
                            }
                        />

                    </ContentInput>
                </Container>
            </Footer>
        </ContainerEditMessage>
    )
});
export default EditMessage;
