import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {
    Body,
    Container,
    ContainerMessage,
    Content,
    ContentError,
    ContentProgress,
    ContentFooter,
    ContentTitle,
    TextError,
    TextTitle, ContainerTitle
} from "@dropDesk/presentation/components/messages/message_box/styled";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import React, {useEffect, useRef} from "react";
import ButtonActionMessage from "@dropDesk/presentation/components/messages/message_box/button_action";
import AvatarImage from "@dropDesk/presentation/components/avatar";
import LocalizationMessage
    from "@dropDesk/presentation/components/messages/message_box/localization_message/locatization_message";
import ImageMessage from "@dropDesk/presentation/components/messages/message_box/image_message/image_message";
import AudioPlayer from "@dropDesk/presentation/components/audio_player/audio_player";
import ReplyMessage from "@dropDesk/presentation/components/messages/message_box/reply_message";
import VideoMessage from "@dropDesk/presentation/components/messages/message_box/video_message";
import VcardMessage from "@dropDesk/presentation/components/messages/message_box/vcard_message";
import SystemMessage from "@dropDesk/presentation/components/messages/message_box/system_message";
import FileMessage from "@dropDesk/presentation/components/messages/message_box/file_message";
import {Progress} from "@dropDesk/presentation/components/loadings/progress_bar";
import firebaseStorage from "firebase/storage";
import UploadTask = firebaseStorage.UploadTask;
import {
    MessageChatType,
    MessageStatus,
    ReplyEntity
} from "@dropDesk/domain/entities/ticket/message/external/message_entity";
import TextMessage from "@dropDesk/presentation/components/messages/message_box/text_message";
import DeletedMessage from "@dropDesk/presentation/components/messages/message_box/deleted_message";
import StatusMessage from "@dropDesk/presentation/components/messages/message_box/status_message";
import WarningMessage from "src/presentation/components/messages/message_box/warning_message";
import ReactMessage from "@dropDesk/presentation/components/messages/message_box/react_message/list";
import InputReactMessage from "@dropDesk/presentation/components/messages/message_box/react_message/input";
import ArrowMenuPopover from "@dropDesk/presentation/components/messages/message_box/arrow_menu_popover";
import {observer} from "mobx-react";
import {UserEntity} from "@dropDesk/domain/entities/user/user.entity";
import {Tooltip} from "antd";
import {SharedColors} from "@dropDesk/domain/entities/theme/app_colors";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";
import {ConstantsKeys} from "@dropDesk/domain/entities/constants/constants_keys";
import {
    areReactionsEqual
} from "@dropDesk/presentation/components/chat_view/chat_message/message_box/use_message_action";

export enum MessageBoxStatus {
    waiting = 'waiting',
    sent = 'sent',
    received = 'received',
    read = 'read'
}

const MessageBox = React.memo((
    {
        message,
        useReplyButton,
        focus,
        onReplyClick,
        resetMessageFocused,
        useDeleteMessageButton,
        onReplyMessageClick,
        onRetrySendMessageError,
        status,
        cancelUpload,
        useHighLightHashTag,
        maxWidth,
        useEditMessageButton,
        handleRemoveReaction,
        canRemoveReaction,
        handleAddReaction,
        lastUsedReactions,
        userClient,
        attendant,
        handleDeleteMessage,
        handleEditMessage,
        isEdit,
        compactMode,
        reactions,
        useReactionButton,
        imageMessages,
        showOriginalFile,
        setLoaded,
    }: {
        message: TicketMessageEntity
        useReplyButton: boolean
        focus: boolean
        onReplyClick: (message: TicketMessageEntity) => void
        resetMessageFocused: () => void
        useDeleteMessageButton: boolean
        onReplyMessageClick: (message: ReplyEntity) => void
        onRetrySendMessageError: (message: TicketMessageEntity) => void
        status?: MessageBoxStatus;
        cancelUpload: (uploadTask?: UploadTask) => void;
        useHighLightHashTag?: boolean;
        maxWidth?: number;
        useEditMessageButton: boolean
        handleRemoveReaction: (reaction: TicketMessageEntity, messageReacted: TicketMessageEntity) => Promise<void>
        canRemoveReaction: boolean
        handleAddReaction: (reaction: string, messageToReacted: TicketMessageEntity) => Promise<void>
        lastUsedReactions: string[]
        userClient?: UserEntity
        attendant?: UserEntity
        handleDeleteMessage: (message: TicketMessageEntity) => void
        handleEditMessage: (editText: string, currentMessage: TicketMessageEntity) => void
        isEdit?: boolean;
        compactMode?: boolean;
        useReactionButton?: boolean;
        reactions: TicketMessageEntity[],
        imageMessages?: TicketMessageEntity[],
        showOriginalFile: (message: TicketMessageEntity) => void
        setLoaded: (message: TicketMessageEntity) => void
    }
) => {

    const prevProps = useRef(focus);
    const messageRef = useRef<HTMLDivElement>(null);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const currentUser = appController.user!;
    const position = (message.senderId === currentUser?.id || (message.sender?.isAttendant && currentUser.isAttendant) || (!!message.automatic && !!message.fromMe)) ? 'right' : 'left';
    const isDarkTheme: boolean = colors.isDarkTheme;
    //const backgroundMessage = position === "left" ? isDarkTheme ? shadeOrLighten(colors.text, -5) : colors.onSecondary : isDarkTheme ? shadeOrLighten(colors.primaryInverse, -15) : colors.tertiary;
    const backgroundMessage = position === "left" ? colors.backgroundLeftChatBox : colors.tertiary;
    const color = position === "left" ? colors.text : isDarkTheme ? colors.text : colors.text;
    const hint = position === 'right' ? colors.hintTertiary : colors.hint;
    const replyMessage = message.reply;
    const useStatus: boolean = position === "right" && !message.isPrivate;
    const urlDownload = message.urlDownload;
    const pushName = message.automatic ? ConstantsKeys.assistantVirtualName : message.sender?.name ?? '';
    const urlProfile = message.automatic ? null : message.sender?.urlImageProfile;
    console.log('message',message);

    useEffect(() => {
        if (prevProps.current !== focus && focus === true) {
            if (messageRef) {
                messageRef.current?.scrollIntoView({
                    block: 'center',
                    behavior: 'auto',
                });
                resetMessageFocused();
            }
        }
        prevProps.current = focus;
    }, [focus, prevProps]);

    const getComponentMessage = (message: TicketMessageEntity): React.ReactNode => {

        const components: Record<MessageChatType, React.ReactNode> = {

            [MessageChatType.textMessage]:
                <TextMessage useHighLightHashTag={useHighLightHashTag} message={message} color={color}/>,

            [MessageChatType.unknown]:
                <TextMessage useHighLightHashTag={useHighLightHashTag} message={message} color={color}/>,

            [MessageChatType.systemMessage]:
                <SystemMessage isDarkTheme={isDarkTheme} message={message}/>,

            [MessageChatType.audioMessage]:
                <AudioPlayer
                    src={urlDownload}
                    id={message.id}
                />,

            [MessageChatType.documentMessage]:
                <FileMessage
                    fileName={message.getFileName}
                    isDarkTheme={isDarkTheme}
                    background={backgroundMessage}
                    caption={message.documentMessage?.caption}
                    message={message}
                    color={color}
                    position={position}
                />,

            [MessageChatType.protocolMessage]: <></>,

            [MessageChatType.imageMessage]:
                <ImageMessage
                    color={color}
                    width={200}
                    hint={hint}
                    message={message}
                    imageMessages={imageMessages ?? []}
                    showOriginalFile={showOriginalFile}
                />,
            [MessageChatType.stickerMessage]:
                <ImageMessage
                    color={color}
                    width={150}
                    height={150}
                    hint={hint}
                    message={message}
                    imageMessages={imageMessages ?? []}
                    showOriginalFile={showOriginalFile}
                />,

            [MessageChatType.videoMessage]:
                <VideoMessage
                    message={message}
                    color={color}
                    showOriginalFile={showOriginalFile}
                    hint={hint}
                    setLoaded={setLoaded}
                />,

            [MessageChatType.reactionMessage]: null,
            [MessageChatType.viewOnceMessage]: null,
            [MessageChatType.contactMessage]:
                <VcardMessage contactMessage={message.contactMessage} color={color} hint={hint}/>,

            [MessageChatType.locationMessage]:
                <LocalizationMessage
                    latitude={message.locationMessage?.degreesLatitude!}
                    longitude={message.locationMessage?.degreesLongitude!}
                    caption={`${message.locationMessage?.name}${message.locationMessage?.address ? ` ${message.locationMessage?.address}` : ''}`}
                    color={color}
                />,
        };
        return message.hasError ?
            <WarningMessage
                messageText={message.getMessageError}
                color={color}/>
            :
            components[message.type];
    }
    return (
        <Container>
            <ContainerMessage
                ref={messageRef}
                backgroundArrow={isDarkTheme && position === 'left' ? SharedColors.white : SharedColors.black}
            >
                {message.type === MessageChatType.systemMessage ? getComponentMessage(message)
                    : (
                        <Content
                            position={position}
                            background={backgroundMessage}
                            isDarkTheme={isDarkTheme}
                            focus={focus}
                            maxWidth={maxWidth}
                            isEdit={isEdit}
                            isCompactMode={compactMode ?? false}
                            hint={hint}
                            shadow={colors.isDarkTheme ? SharedColors.white : SharedColors.black}
                        >
                            <Body>

                                {useReplyButton &&
                                    <>
                                        <ButtonActionMessage
                                            onClick={() => onReplyClick(message)}
                                            position={position}
                                            isDarkTheme={isDarkTheme}
                                            nameIcon={ICONS_DROPDESK.chatting}
                                            spacePosition={45}
                                        />
                                        {useReactionButton && <InputReactMessage
                                            position={position}
                                            isDarkTheme={isDarkTheme}
                                            handleAddReaction={(reaction) => handleAddReaction(reaction, message)}
                                            lastUsedReactions={lastUsedReactions}
                                            userLoggedId={currentUser.id}
                                            reactions={reactions}
                                            reactComponent={
                                                <ButtonActionMessage
                                                    onClick={() => {

                                                    }}
                                                    position={position}
                                                    isDarkTheme={isDarkTheme}
                                                    nameIcon={ICONS_DROPDESK.happyFace}
                                                    spacePosition={80}
                                                />
                                            }
                                        />}
                                    </>}

                                <ContainerTitle>
                                    {message.sender && !compactMode &&
                                        <ContentTitle color={colors.text}>
                                            <AvatarImage
                                                useCursorPointer={!!urlProfile}
                                                title={pushName}
                                                size={22}
                                                letterSize={12}
                                                url={urlProfile}
                                                isInverseBackground={position === 'right'}
                                                round={true}
                                                hasPreviewImage={true}
                                                onClick={(event) => event.stopPropagation()}
                                                useLetterSpacing={false}
                                                withBorder={true}
                                            />
                                            <Tooltip title={pushName}>
                                                <TextTitle>{pushName}</TextTitle>
                                            </Tooltip>
                                        </ContentTitle>}
                                    {((useDeleteMessageButton || useEditMessageButton || useReplyButton) && userClient) &&
                                        <ArrowMenuPopover
                                            canDelete={useDeleteMessageButton}
                                            canEdit={useEditMessageButton}
                                            onClickReply={() => onReplyClick(message)}
                                            canReply={useReplyButton}
                                            canReact={!!useReactionButton}
                                            position={position}
                                            isDarkTheme={isDarkTheme}
                                            handleAddReaction={(reaction) => handleAddReaction(reaction, message)}
                                            userLogged={currentUser}
                                            lastUsedReactions={lastUsedReactions}
                                            message={message}
                                            handleEditMessage={handleEditMessage}
                                            handleDeleteMessage={handleDeleteMessage}
                                            userClient={userClient}
                                            color={color}
                                            reactions={reactions}
                                            attendant={attendant}
                                        />}
                                </ContainerTitle>
                                {replyMessage && !message.deleted &&
                                    <ReplyMessage
                                        background={backgroundMessage}
                                        replyMessage={replyMessage}
                                        onReplyMessageClick={onReplyMessageClick}
                                        color={color}
                                    />
                                }

                                {message.deleted && <DeletedMessage hint={hint}/>}
                                {!message.deleted && getComponentMessage(message)}
                                <StatusMessage status={status} message={message} useStatus={useStatus} hint={hint}/>

                            </Body>
                        </Content>
                    )}

            </ContainerMessage>
            <ContentFooter position={position}>
                {!message.deleted &&
                    <ReactMessage
                        position={position}
                        isDarkTheme={isDarkTheme}
                        currentUserId={currentUser.id}
                        canRemoveReaction={canRemoveReaction}
                        handleRemoveReaction={(reaction) => handleRemoveReaction(reaction, message)}
                        reactions={reactions}
                    />
                }
                {(!message.hasSendError && !!message.uploadOptions && !message.createdAt) &&
                    <ContentProgress>
                        <>
                            <Progress progress={message.uploadOptions.progress} width={150}
                                      type={'line'}/>
                            {message.uploadOptions.progress !== 100 && <DropDeskIcon
                                icon={ICONS_DROPDESK.cancel}
                                color={colors.textError}
                                onClick={() => cancelUpload(message.uploadOptions?.referenceUploadTask)}
                                size={15}
                                style={{cursor: 'pointer', marginBottom: -3, marginLeft: -3}}
                                useDarkenInHover={true}
                            />}
                        </>
                    </ContentProgress>
                }

                {message.hasSendError &&
                    <ContentError onClick={() => onRetrySendMessageError(message)}>
                        < TextError color={colors.text}>Clique para reenviar</TextError>
                        <DropDeskIcon
                            icon={ICONS_DROPDESK.warningFill}
                            color={colors.textError}
                            style={{cursor: 'pointer', marginLeft: 5}}
                            size={15}
                            useDarkenInHover={true}
                        />
                    </ContentError>
                }

            </ContentFooter>


        </Container>
    )
}, (prevProps, nextProps) => {
    return prevProps.message.id === nextProps.message.id
        && prevProps.message.updatedAt === nextProps.message.updatedAt
        && prevProps.useReplyButton === nextProps.useReplyButton
        && prevProps.focus === nextProps.focus
        && prevProps.useDeleteMessageButton === nextProps.useDeleteMessageButton
        && prevProps.status === nextProps.status
        && prevProps.message.status === nextProps.message.status
        && prevProps.useHighLightHashTag === nextProps.useHighLightHashTag
        && prevProps.useEditMessageButton === nextProps.useEditMessageButton
        && prevProps.canRemoveReaction === nextProps.canRemoveReaction
        && prevProps.attendant?.id === nextProps.attendant?.id
        && prevProps.isEdit === nextProps.isEdit
        && prevProps.compactMode === nextProps.compactMode
        && prevProps.message.edited === nextProps.message.edited
        && prevProps.useReactionButton === nextProps.useReactionButton
        && prevProps.imageMessages?.length === nextProps.imageMessages?.length
        && prevProps.message.showOriginal === nextProps.message.showOriginal
        && areReactionsEqual(prevProps.reactions, nextProps.reactions);
});
export default MessageBox;
