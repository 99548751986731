import {
    Container,
    ContainerLogin,
    ContainerInputs,
    Section, Image, Title, ContentImage, Content, TextCreateAccount, ContentSubTitle, TextCreateFree
} from "./styled";
import {LoginController} from "../controller/login.controller";
import {useInjection} from "inversify-react";
import {observer} from "mobx-react";
import Inputs from "@dropDesk/presentation/pages/login/ui/inputs";
import ButtonLogin from "@dropDesk/presentation/pages/login/ui/button_login";
import {FormEvent, useEffect} from "react";
import {useValidatorLogin} from "@dropDesk/presentation/pages/login/ui/inputs/validator";
import {useLocation, useNavigate} from "react-router-dom";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {UserController} from "@dropDesk/presentation/pages/user/controller/user.controller";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import {useTranslation} from "react-i18next";
import Carousel from "@dropDesk/presentation/pages/login/ui/carousel";
import ActionsAccount from "@dropDesk/presentation/pages/login/ui/actions_account";
import {useBetween} from "use-between";
import DropDeskLoading from "@dropDesk/presentation/components/loadings/loading_dropdesk";
import Footer from "@dropDesk/presentation/pages/login/ui/footer";
import {RoutesEnum} from "@dropDesk/domain/entities/routes/routes_enum";

const Login = (observer(() => {

    const controller = useInjection(LoginController);
    const userController = useInjection(UserController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const {loginEntity, validate, setLoginEntity} = useBetween(useValidatorLogin);
    const navigate = useNavigate();
    const {t} = useTranslation();


    const onSuccess = async () => {
        const route = await appController.getCurrentUser();
        navigate(route, {replace: true});
        setLoginEntity({email: "", password: ""});
    }

    const onSuccessLegacy = () => {
        navigate(`${RoutesEnum.migrated}?email=${loginEntity.email ? encodeURIComponent(loginEntity.email) : loginEntity.email}`, {replace: true});
        setLoginEntity({email: "", password: ""});
    }

    const onSuccessForgotEmail = (key: string, email?: string) => {
        navigate(`${RoutesEnum.changePassword}?token=&email=${email ? encodeURIComponent(email) : ''}`);
        toastMessage.success(t(key));
    }

    async function handleSubmit(event: FormEvent) {
        event.preventDefault();
        const validLogin = validate(true, true);

        if (validLogin) {
            await controller.login(loginEntity, onSuccess, onSuccessLegacy);
        }

    }


    return (

        <Container>

            {!appController.isUserClient && <Carousel/>}

            <Section background={colors.delicateBackground}>
                <Content>
                    <ContainerLogin onSubmit={handleSubmit}>
                        <ContentImage>
                            <Image src={appController.logoBrand} alt={"Logo Marca"}/>
                        </ContentImage>
                        <ContentSubTitle>
                            <Title color={colors.text}>Entre com seu email e senha.</Title>
                            <TextCreateAccount
                                color={colors.text}
                                to={RoutesEnum.companyCreate}
                            >Novo por aqui?
                                <TextCreateFree color={colors.textLink}>Crie sua conta grátis</TextCreateFree>
                            </TextCreateAccount>
                        </ContentSubTitle>
                        <ContainerInputs>
                            <Inputs
                                loading={controller.loading}
                            />

                            <ButtonLogin
                                handleSubmit={handleSubmit}
                                loading={controller.loading}
                                isValidForm={validate(true, true)}
                            />

                            <ActionsAccount
                                onForgotEmail={(email) => userController.forgot(email, onSuccessForgotEmail)}
                                loading={userController.loading}
                            />

                        </ContainerInputs>

                    </ContainerLogin>

                </Content>
                <Footer/>
            </Section>
            {(controller.loading || appController.loading || userController.loading) && <DropDeskLoading
                height={250}
                description={controller.loadingMessage ?? appController.loadingMessage ?? userController.loadingMessage}
            />}

        </Container>
    );
}));

export default Login;
