import "reflect-metadata";
import {inject, injectable} from "inversify";
import { AuthRepository } from "@dropDesk/domain/repositories/auth/auth.repository";
import {TwoFactorAuthResponse, VoidSuccess} from "@dropDesk/domain/entities/response/response";


@injectable()
export class LoginUseCase {
  private _repository: AuthRepository;

  constructor(
    @inject(AuthRepository) repository: AuthRepository
  ) {
    this._repository = repository;
  }

  public call(email: string, password: string): Promise<VoidSuccess> {
    return this._repository.login(email, password);
  }
}
