import {useInjection} from "inversify-react";
import {AppController} from "@dropDesk/presentation/app/app.controller";
import {Button, Container, ContentFileProgress, ContentIconFile, ContentTextFile} from "./styled";
import React from "react";
import {observer} from "mobx-react";
import {Progress} from "@dropDesk/presentation/components/loadings/progress_bar";
import toastMessage from "@dropDesk/utils/toast_message/toast_message";
import LinkFy from "@dropDesk/presentation/components/linkify";
import {IoController} from "@dropDesk/presentation/pages/io/io.controller";
import {TicketMessageEntity} from "@dropDesk/domain/entities/ticket/message/ticket_message.entity";
import {ICONS_DROPDESK} from "@dropDesk/storage/icons/icons.d";
import DropDeskIcon from "@dropDesk/storage/icons";

const FileMessage = observer((
    {
        caption,
        isDarkTheme,
        background,
        fileName,
        message,
        color,
        position
    }: {
        caption?: string | null
        fileName?: string
        isDarkTheme: boolean
        background: string
        message: TicketMessageEntity
        color: string
        position: string
    }
) => {

    const ioController = useInjection(IoController);
    const appController = useInjection(AppController);
    const colors = appController.theme.colorScheme;
    const displayProgress = ioController.fileProgress.progress > 0;
    const downloadFile = () => {
        ioController.observeDownloadFile(message.urlDownload ?? '', message.getFileName)
    }

    return (
        <Container>

            <Button
                background={colors.isDarkTheme ? colors.backgroundInput : colors.buttonOnBackground}
                isDarkTheme={isDarkTheme}
                onClick={() => !displayProgress ?
                    downloadFile() :
                    toastMessage.info("Já possui um download em andamento, tente novamente após o término...")
                }
            >
                <ContentIconFile>
                    <DropDeskIcon icon={ICONS_DROPDESK.documentOutline} size={20} color={color}/>
                </ContentIconFile>
                <ContentTextFile color={color}>
                    {fileName}
                </ContentTextFile>
                <ContentFileProgress>
                    {displayProgress &&
                        <Progress progress={ioController.fileProgress.progress} width={30} type={'circle'} height={30}
                                  color={color}/>
                    }
                    {!displayProgress &&
                        <DropDeskIcon icon={ICONS_DROPDESK.download} size={20} color={color}/>
                    }
                </ContentFileProgress>
            </Button>

            {caption && <LinkFy color={color} text={caption}/>}
        </Container>
    )
});
export default FileMessage;
